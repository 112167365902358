import React, { useState, useMemo } from 'react';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { MaterialReactTable } from 'material-react-table';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import fetchData from '../../app/FetchData.js';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AppLoading from '../../app/AppLoading.tsx';
import dayjs from 'dayjs';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

interface Props {
  windowHeight: number;
}

const ViewConcurrencia: React.FC<Props> = (props) => {
  const [mesIni, setMesIni] = useState(dayjs().startOf('month'));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleGetConcurrencia = async () => {
    if (mesIni) {
      try {
        setLoading(true);
        const mesIniTimestamp = mesIni.valueOf();
        const mesFin = mesIni.clone().add(1, 'month').startOf('month');
        const mesFinTimestamp = mesFin.valueOf();

        const url = 'https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/';
        const context = 'informes';
        const requestPayload = {
          mesIni: mesIniTimestamp,
          mesFin: mesFinTimestamp,
          action: 'concurrencia',
        };

        const respData = await fetchData(url, context, requestPayload);
        console.log('Datos recibidos:', respData);

        if (respData && respData.rows) {
          const datos = respData.rows.map((row) => ({
            cuenta: row.cuenta,
            cliente: row.cliente,
            rds_id: row.rds_id,
            endpoint: row.endpoint,
            name_database: row.name_database,
            fecha_creacion: row.fecha_creacion,
            nit: row.nit,
            razon_social: row.razon_social,
            tamano: row.tamano,
            fecha_dia: row.fecha_dia,
            total_concurrencia: row.total_concurrencia,
            engine: row.engine,
            store_type: row.store_type,
            store_throughput: row.store_throughput,
            iops: row.iops,
            store: row.store,
            rds_class: row.rds_class,
            status: row.status,
            tipo_despliegue: row.tipo_despliegue,
            pais: row.pais,
            producto: row.producto,
          }));
          console.log('Datos para la tabla:', datos);
          setData(datos);
        } else {
          console.error('La respuesta no contiene datos esperados:', respData);
        }
      } catch (error) {
        console.error('Error al obtener concurrencia:', error);
      } finally {
        setLoading(false);
      }
    } else {
      alert('Por favor, selecciona el mes de inicio.');
    }
  };

  const columns = useMemo(
    () => [
      { header: 'cuenta', accessorKey: 'cuenta' },
      { header: 'cliente', accessorKey: 'cliente' },
      { header: 'rds_id', accessorKey: 'rds_id' },
      { header: 'endpoint', accessorKey: 'endpoint' },
      { header: 'name_database', accessorKey: 'name_database' },
      { header: 'fecha_creacion', accessorKey: 'fecha_creacion' },
      { header: 'nit', accessorKey: 'nit' },
      { header: 'razon_social', accessorKey: 'razon_social' },
      { header: 'tamano', accessorKey: 'tamano' },
      { header: 'fecha_dia', accessorKey: 'fecha_dia' },
      { header: 'total_concurrencia', accessorKey: 'total_concurrencia' },
      { header: 'engine', accessorKey: 'engine' },
      { header: 'store_type', accessorKey: 'store_type' },
      { header: 'store_throughput', accessorKey: 'store_throughput' },
      { header: 'iops', accessorKey: 'iops' },
      { header: 'store', accessorKey: 'store' },
      { header: 'rds_class', accessorKey: 'rds_class' },
      { header: 'status', accessorKey: 'status' },
      { header: 'tipo_despliegue', accessorKey: 'tipo_despliegue' },
      { header: 'pais', accessorKey: 'pais' },
      { header: 'producto', accessorKey: 'producto' },
    ],
    []
  );

  const customTableContainerProps = {
    sx: {
      maxHeight: '600px', // Altura máxima de la tabla
      overflow: 'auto',
    },
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  return (
    <React.Fragment>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            <DatePicker
              label={'Mes y año'}
              views={['month', 'year']}
              onChange={(newValue) => setMesIni(newValue?.startOf('month'))}
              value={mesIni}
            />
            <Button variant="contained" size="large" onClick={handleGetConcurrencia}>
              Get Concurrencia
            </Button>
          </DemoContainer>
        </LocalizationProvider>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.8)', // Fondo semitransparente para destacar el loading
            }}
          >
            <AppLoading {...props} />
          </Box>
        ) : (
          <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
            <MaterialReactTable
              columns={columns}
              data={data}
              enableColumnResizing
              enableGrouping
              enableStickyHeader
              enableStickyFooter
              initialState={{
                density: 'compact',
                expanded: true,
                pagination: { pageIndex: 0, pageSize: 30 },
              }}
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              muiTableContainerProps={customTableContainerProps}
              renderTopToolbarCustomActions={({ table }) => (
                <Box sx={{ display: 'absolute', gap: '160px', padding: '4px', flexWrap: 'wrap' }}>
                  <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>
                    Export All Data
                  </Button>
                </Box>
              )}
            />
          </Box>
        )}
      </div>
    </React.Fragment>
  );
};

export default ViewConcurrencia;
