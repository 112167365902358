import React, { useMemo } from 'react';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MaterialReactTable, type MRT_ColumnDef, } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AppLoading from '../app/AppLoading.tsx';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const Example = (props:any) => {
  const [mesIni, setMesIni] = React.useState<Dayjs | null>(dayjs(new Date()));    
  const data:any = props.data&&props.data.body?props.data.body.rows:[];

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const countRows = useMemo(
    () => data.reduce((acc, curr) => acc + 1, 0),
    [],
  );

  const columns = useMemo(
    () => [
      { header: 'cuenta', accessorKey: 'cuenta', },
      { header: 'cliente', accessorKey: 'cliente', },
      { header: 'rds_id', accessorKey: 'rds_id', },
      { header: 'endpoint', accessorKey: 'endpoint', },
      { header: 'name_database', accessorKey: 'name_database', },
      { header: 'fecha_creacion', accessorKey: 'fecha_creacion', },
      { header: 'nit', accessorKey: 'nit', },
      { header: 'razon_social', accessorKey: 'razon_social', },
      { header: 'tamano', accessorKey: 'tamano', },
      { header: 'fecha_dia', accessorKey: 'fecha_dia', },
      { header: 'total_concurrencia', accessorKey: 'total_concurrencia', },
      { header: 'engine', accessorKey: 'engine', },
      { header: 'store_type', accessorKey: 'store_type', },
      { header: 'store_throughput', accessorKey: 'store_throughput', },
      { header: 'iops', accessorKey: 'iops', },
      { header: 'store', accessorKey: 'store', },
      { header: 'rds_class', accessorKey: 'rds_class', },
      { header: 'status', accessorKey: 'status', },
      { header: 'tipo_despliegue', accessorKey: 'tipo_despliegue', },
      { header: 'pais', accessorKey: 'pais', },
      { header: 'producto', accessorKey: 'producto', },
      
    ],[countRows]
  );

  const customTableContainerProps = {
    sx: { maxHeight: props.windowHeight-262 },
  };
  return (    
    <React.Fragment>     
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={[]}>
          <DatePicker 
            label={'mes y año'} 
            views={['month', 'year']} 
            onChange={(newValue) => setMesIni(newValue)}
            value={mesIni}
            />   
          <Button variant="contained" size="large" onClick={() => props.onClickTEAM({mesIni})}>
              Get Concurrencia
          </Button>
        </DemoContainer>
      </LocalizationProvider>
      {
      props.blocking ?  
        <AppLoading {...props}/>
        :
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnResizing enableGrouping enableStickyHeader enableStickyFooter
          initialState={{ density: 'compact',expanded: true, pagination: { pageIndex: 0, pageSize: 100 }, }}
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          muiTableContainerProps= {customTableContainerProps}
          renderTopToolbarCustomActions= {({ table }) => (
            <Box sx={{ display: 'flex',gap: '16px',padding: '4px',flexWrap: 'wrap',}}>
              <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}> Export All Data</Button>
            </Box>
          )}
        />
      }
    </React.Fragment>
  );
};

export default Example;
