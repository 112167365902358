import React, { useMemo } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';  //https://mui.com/material-ui/material-icons/
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/AddCircle';
import {
  IconButton, Tooltip, Box, Stack, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import Modal from '@mui/material/Modal';
import ViewNewEdit from './ViewNewEdit.tsx';
import AppLoading from '../../app/AppLoading.tsx';
import fetchData from '../../app/FetchData.js';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  //width: '500px',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 54,
  pl: 2, pr: 2, pt: 0, pb: 2,
};

const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";
const context = 'ec2-aws-resize';

const Example = (props: any) => {

  const [data, setData] = React.useState([]);
  const [blocking, setBlocking] = React.useState(false);
  const [dataInstance, setDataInstance] = React.useState([]);
  const [msgAction, setMsgAction] = React.useState(context);

  const [cuentaSelect, setCuentaSelect] = React.useState({});
  const [rowSelect, setRowInstanceSelect] = React.useState("node");
  const [openMonitor, setOpenMonitor] = React.useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [rowToDelete, setRowToDelete] = React.useState(null);

  const handleOpenMonitor = (row) => {
    setOpenMonitor(true);
    setRowInstanceSelect(row);
  };

  const handleCloseMonitor = async (refresh) => {
    console.log("refresh..........", refresh);
    setOpenMonitor(false);
    if (refresh && refresh === true) {
      await loadList(cuentaSelect);
    }
  };

  const handleClick = async (dataPage: any) => {
    if (dataPage.newCuentaValue) {
      setCuentaSelect(dataPage.newCuentaValue);
      await loadList(dataPage.newCuentaValue);
    }
  };

  const loadList = async (cuentaSelectSid) => {
    setMsgAction("cargando");
    const params = {
      account: cuentaSelectSid,
      action: "get"
    };
    setBlocking(true);
    const resp = await fetchData(url, context, params);
    setData(resp.rows);
    setDataInstance(resp.bodyAdd ? resp.bodyAdd.rows : []);
    setBlocking(false);
  };

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const handleDelete = async () => {
    const params = {
      action: "DELETE",
      object: {
        id: rowToDelete.original ? rowToDelete.original.id : -1
      }
    };
    setBlocking(true);
    setMsgAction("eliminando");
    let resp = await fetchData(url, context, params);
    setBlocking(false);
    setOpenConfirmDelete(false);
    await loadList(cuentaSelect);
  };

  const columns = useMemo(
    () => [
      { header: 'id Instance', accessorKey: 'instance_id', },
      { header: 'Instancia', accessorKey: 'instance_name', },
      { header: 'Instance Base', accessorKey: 'instance_type_base', },
      { header: 'Instance Resize', accessorKey: 'instance_type_resize', },
      { header: 'Instance B. Resp', accessorKey: 'instance_type_base_respaldo', },
      { header: 'Instance R. Resp', accessorKey: 'instance_type_resize_respaldo', },
      { header: 'Hora Base', accessorKey: 'hora_base', },
      { header: 'Hora Resize', accessorKey: 'hora_resize', },
      { header: 'Dias', accessorKey: 'dias', },
      { header: 'Comentario', accessorKey: 'comentario', },
      { header: 'fe Creacion', accessorKey: 'fecha_creacion', },
      { header: 'fe actualizacion', accessorKey: 'fecha_actualizacion', },
    ], []
  );

  const customTableContainerProps = {
    sx: { maxHeight: props.windowHeight - 270 },
  };

  return (
    <React.Fragment>
      <Stack spacing={2} sx={{ width: 300, padding: 1 }}>
        <Autocomplete
          disablePortal
          id="cuentas-resize"
          options={cuentasResize.map((option) => option.title)}
          renderInput={(params) => <TextField {...params} label="Cuentas-Resize" />}
          onInputChange={(event, newCuentaValue) => {
            handleClick({ newCuentaValue });
          }}
        />
      </Stack>
      {blocking ?
        <AppLoading {...props} titleCustom={msgAction} />
        :
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnResizing enableGrouping getRowId={(row) => row.id} enableStickyHeader enableStickyFooter
          initialState={{ density: 'compact', expanded: true, pagination: { pageIndex: 0, pageSize: 100 }, }}
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          muiTableContainerProps={customTableContainerProps}
          enableRowActions
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: 'actions',
              size: 100,
            },
          }}
          renderRowActions={({ row, table }) => (
            <Box sx={{ w: 300 }}>
              <Tooltip title="Edit">
                <IconButton onClick={() => handleOpenMonitor(row)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={() => {
                  setRowToDelete(row);
                  setOpenConfirmDelete(true);
                }}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '4px',
                flexWrap: 'wrap',
              }}
            >
              <Button size="small"
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}>
                Export All Data
              </Button>

              <Button size="small"
                onClick={() => handleOpenMonitor([])}
                startIcon={<AddIcon />}>
                add Expresión
              </Button>
            </Box>
          )}
        />
      }
      <Modal
        open={openMonitor}
        onClose={handleCloseMonitor}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ViewNewEdit
            row={rowSelect}
            dataInstance={dataInstance}
            handleCloseMonitor={handleCloseMonitor}
            cuentaSelect={cuentaSelect}
          />
        </Box>
      </Modal>
      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
      >
        <DialogTitle>Confirmar Eliminacion</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {`Esta seguro que desea eliminar: ${rowToDelete?.original?.instance_id}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDelete(false)}>No</Button>
          <Button onClick={handleDelete} color="primary">Si</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const cuentasResize = [
  { title: 'pdn' },
  { title: 'pdn-m1' },
  { title: 'pdn-m2' },
  { title: 'pdn-m3' },
  { title: 'pdn-m4' },
  { title: 'pdn-m5' },
  { title: 'zeus' },
  { title: 'staging' },
];

export default Example;
