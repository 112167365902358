import { Box, Grid, Typography, Button, Stack, CircularProgress } from '@mui/material';
import React, { createContext, useState, useEffect, Fragment } from "react";
import RenderDinamico from './RenderDinamico.tsx';
import CustomizedDialogs from '../../app/CustomizedDialogs.tsx';
import AppLoading from '../../app/AppLoading.tsx';
import fetchData from '../../app/FetchData.js';
import TabsDinamicas from './TabsDinamicas.tsx';
import View from './ViewCpu.tsx';
import { MaterialReactTable } from 'material-react-table';

const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";

const ViewData = (props) => {
    const [data, setData] = useState({ diario: null, semanal: null, mensual: null });
    const [dataUsu, setDataUsu] = useState("");
    const [dataClient, setDataClient] = useState("");
    const [dataTextArea, setDataTextArea] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [msgError, setMsgError] = useState({});
    const [vtCpuMax, setVtCpuMax] = useState([]);
    const [vtRamMax, setVtRamMax] = useState([]);
    const [availability, setAvailability] = useState(100);
    // const [timeFilter, setTimeFilter] = useState('diario');
    const [selectedInstance, setSelectedInstance] = useState(null);

    const fetchDataFromApi = async (instanceId) => {
        
        const params = {
            objeto: props.row.original,
            action: "get-by-instance"
        };

        try {
            const data = await fetchData(url, "ec2-disponibilidad", params);
            // console.log('Data....', data)
            //Inicio Trabajo de desestructuracion para los Usuarios
            const dataServers = data.instanciasCliente ? data.instanciasCliente.rows : [];
            const dataUser = data.usuariosCliente ? data.usuariosCliente.rows : [];
            // console.log(dataUser)
            
            setIsLoading(false);
            
            setData(data);
            setDataUsu(dataUser);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setIsError(true);
            setMsgError(error.message);
        }
    };

    useEffect(() => {
            fetchDataFromApi();    
    }, []);

    const handleTabChange = (instanceId) => {
        setSelectedInstance(instanceId); // Actualiza la instancia seleccionada
        fetchDataFromApi(instanceId)
        // console.log('Instancia....', instanceId)
    };

    // const handleFilterChange = (filter) => {
    //     setIsLoading(true);
    //     setTimeFilter(filter);
    // };

    // const handleRefresh = () => {
    //     setIsLoading(true);
    //     fetchDataFromApi(timeFilter);
    // };

    // const memoryInfo = props.ec2Info.original.memory_info / 1024;

    // Define the color based on the availability value
    // const availabilityColor = availability > 99.80 ? 'green' : 'red';

    //Validacion Usuarios
    // const totalUsuarios = data[timeFilter]?.Usuarios?.rows?.[0]?.total_usuarios ?? 0;
    // const totalCaidas = data[timeFilter]?.Caidas?.rowCount ?? 0;

    return (
        <Fragment>
            {isLoading ? (
                <Box><AppLoading {...props} /></Box>
            ) : (
                <Box>
                    <TabsDinamicas data={data} dataUser={dataUsu} onTabChange={handleTabChange}/>
                    
                </Box>
            )}
        </Fragment>
    );
}

export default ViewData;