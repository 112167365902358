import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect, Fragment } from "react";
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import AppLoading from '../../app/AppLoading.tsx';
import fetchData from '../../app/FetchData.js';

var cuentaSelectResize = "none";
var opcionesInstances = [];
const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";
const context = 'ec2-aws-resize';
const ViewNewEdit = (props: any) => {

  const [instanceId, setInstanceId] = useState(props.row.original ? props.row.original.instance_id : "");
  const [instancia, setInstance] = useState(props.row.original ? props.row.original.instance_name : "");
  const [instanceBase, setInstanceBase] = useState(props.row.original ? props.row.original.instance_type_base : "");
  const [instanceResize, setInstanceResize] = useState(props.row.original ? props.row.original.instance_type_resize : "");
  const [instanceBaseRespaldo, setInstanceBaseRespaldo] = useState(props.row.original ? props.row.original.instance_type_base_respaldo : "");
  const [instanceResizeRespaldo, setInstanceResizeRespaldo] = useState(props.row.original ? props.row.original.instance_type_resize_respaldo : "");
  const [horaBase, setHoraBase] = useState<number>(props.row.original ? props.row.original.hora_base : 0);
  const [horaResize, setHoraResize] = useState<number>(props.row.original ? props.row.original.hora_resize : 0);
  const [dias, setDias] = useState(props.row.original ? props.row.original.dias : "");
  const [comentario, setComentario] = useState(props.row.original ? props.row.original.comentario : "");
  const [feActualizacion, setFeActualizacion] = useState(props.row.original ? props.row.original.fecha_creacion : "");
  const [feCrecion, setFeCrecion] = useState(props.row.original ? props.row.original.fecha_actualizacion : "");
  const [blocking, setBlocking] = React.useState(false);
  const [msgAction, setMsgAction] = React.useState(context);
  const [errorMessage, setErrorMessage] = useState<string>(""); // Estado para el mensaje de error
  const [errors, setErrors] = useState({
    instanceId: false,
    instanceBase: false,
    instanceResize: false,
    instanceBaseRespaldo: false,
    instanceResizeRespaldo: false,
    horaBase: false,
    horaResize: false,
    dias: false,

  });

  if (opcionesInstances.length == 0 || props.cuentaSelect != cuentaSelectResize) {
    opcionesInstances = [];
    for (let objInsEc2 of props.dataInstance) {
      opcionesInstances.push({ "title": objInsEc2.title });
    }
    cuentaSelectResize = props.cuentaSelect;
  }

  const handleAccept = async () => {
    // Validar campos requeridos
    const newErrors = {
      instanceId: instanceId === "",
      instanceBase: instanceBase === "",
      instanceResize: instanceResize === "",
      instanceBaseRespaldo: instanceBaseRespaldo === "",
      instanceResizeRespaldo: instanceResizeRespaldo === "",
      horaBase: horaBase === -1,
      horaResize: horaResize === -1,
      // dias: !validateDias(dias),
    };

    setErrors(newErrors);


    // Realiza las validaciones en el orden correcto
    if (Object.values(newErrors).some(error => error)) {
      setErrorMessage("Los campos son requeridos.");
      setErrors(newErrors);
      return;
    } else {
      setErrorMessage(""); // Borra el mensaje de error si no hay errores en los campos requeridos
      setErrors({});
    }
  
    // Validar que horaBase y horaResize no sean iguales
    if (horaBase === horaResize) {
      setErrorMessage("La hora base y resize no pueden ser iguales.");
      return;
    } else {
      setErrorMessage(""); // Borra el mensaje de error si las horas son diferentes
    }
  
    // Validar el formato del campo dias
    const isValid = validateDias(dias);
    if (!isValid) {
      setErrors(prevErrors => ({ ...prevErrors, dias: "Formato inválido. Debe ser [1,2,3,4]" }));
      setErrorMessage ("");
      return;
    } else {
      setErrors(prevErrors => ({ ...prevErrors, dias: "" })); // Borra el mensaje de error si dias cumple con el formato
      setErrorMessage(""); // Borra el mensaje de error si dias cumple con el formato
    }
  

    const params = {
      action: props.row.original ? "UPDATE" : "INSERT",
      object: {
        instanceId,
        instancia,
        instanceBase,
        instanceResize,
        instanceBaseRespaldo,
        instanceResizeRespaldo,
        horaBase,
        horaResize,
        dias,
        comentario,
        cuenta: props.cuentaSelect,
        feActualizacion: new Date(),
        feCrecion: props.row.original ? feCrecion : new Date(),
        id: props.row.original ? props.row.original.id : -1,
      }
    };

    setBlocking(true);
    setMsgAction(props.row.original ? "Actualizando" : "Insertando");
    let resp = await fetchData(url, context, params);
    setBlocking(false);
    console.log("result", resp);
    props.handleCloseMonitor(true);
  };

  const validateDias = (value: string) => {
    const regex = /^\[([0-6](,[0-6])*)?\]$/;
    const isValidFormat = regex.test(value);
    if (!isValidFormat || value === '[]') return false;

    // Convert the string into an array of numbers and check for duplicates
    const numbers = value.slice(1, -1).split(',').map(Number);
    const uniqueNumbers = new Set(numbers);
    return numbers.length === uniqueNumbers.size;
  };

  const handleBlur = () => {
    const isValid = validateDias(dias);
    if (!isValid) {
      setErrors({ ...errors, dias: true });
    }
  };

  return (
    <Fragment>
      <Box sx={{ overflow: 'auto' }}>
        <Typography id="modal-modal-title"
          sx={{ mt: 2, mb: 1, alignItems: 'center', textAlign: 'center', fontSize: 24 }}>
          Instancia
        </Typography>
        {blocking ?
          <AppLoading {...props} titleCustom={msgAction} />
          :
          <Box id="modal-modal-description" component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
              width: 480,
              textAlign: 'center'
            }}
            noValidate
            autoComplete="off">
            <Container maxWidth="sm">
              <Autocomplete
                disablePortal
                style={{ width: 410 }}
                options={opcionesInstances.map((option) => option.title)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="instancia ec2"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-instance', // disable autocomplete and autofill
                    }}
                    error={errors.instanceId}
                  //helperText={errors.instanceId ? "Este campo es obligatorio" : ""}
                  />
                )}
                onChange={(event: any, newValue: string | null) => {
                  let objInstance = props.dataInstance.find(option => option.title === newValue);
                  console.log("objInstance......", objInstance);
                  if (objInstance) {
                    setInstanceId(objInstance.instance_id);
                    setInstance(objInstance.instance_name);
                    setInstanceBase(objInstance.instance_type);
                    setFeActualizacion("");
                  }
                }}
              />
            </Container>

            <TextField size="small" value={instanceId} disabled id="instance-id" label="Instance Id"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInstanceId(event.target.value);
              }}
            />
            <TextField size="small" value={instancia} disabled id="instancia" label="Instancia"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInstance(event.target.value);
              }}
            />

            <Stack spacing={{ xs: 2, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
            <Autocomplete
                value={instanceOptions.find(option => option.value === instanceBase) || null}
                style={{ width: 223 }}
                size="small"
                disablePortal
                options={instanceOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Instance Base:"
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-instance-base',
                    }}
                    error={errors.instanceBase}
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setInstanceBase(newValue.value);
                  } else {
                    setInstanceBase('');
                  }
                }}
              />

              <Autocomplete
                value={instanceOptions.find(Option => Option.value === instanceResize)}
                style={{ width: 223 }}
                size="small"
                disablePortal
                options={instanceOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    id="instance-resize"
                    label="Instance Resize"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-instance-resize', // disable autocomplete and autofill
                    }}
                    error={errors.instanceResize}
                  //helperText={errors.instanceResize ? "Este campo es obligatorio" : ""}
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setInstanceResize(newValue.value);
                  } else {
                    setInstanceResize('');
                  }
                }}
              />
            </Stack>
            <Stack spacing={{ xs: 2, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
              <Autocomplete
                value={instanceOptions.find(Option => Option.value === instanceBaseRespaldo)}
                style={{ width: 223 }}
                size="small"
                disablePortal
                options={instanceOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    id="instance-base-respaldo"
                    label="Instance Base Respaldo"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-instance-base-respaldo', // disable autocomplete and autofill
                    }}
                    error={errors.instanceBaseRespaldo}
                  //helperText={errors.instanceBaseRespaldo ? "Este campo es obligatorio" : ""}
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setInstanceBaseRespaldo(newValue.value);
                  } else {
                    setInstanceBaseRespaldo('');
                  }
                }}
              />
              <Autocomplete
                value={instanceOptions.find(Option => Option.value === instanceResizeRespaldo)}
                style={{ width: 223 }}
                size="small"
                disablePortal
                options={instanceOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    id="instance-resize-respaldo"
                    label="Instance Resize Respaldo"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-instance-resize-respaldo', // disable autocomplete and autofill
                    }}
                    error={errors.instanceResizeRespaldo}
                  //helperText={errors.instanceResizeRespaldo ? "Este campo es obligatorio" : ""}
                  />
                )}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setInstanceResizeRespaldo(newValue.value);
                  } else {
                    setInstanceResizeRespaldo('');
                  }
                }}
              />
            </Stack>
            <TextField size="small" value={horaBase} type="number" required id="hora-base" label="Hora Base" style={{ width: 223 }}
              inputProps={{
                min: 0,
                max: 23,
                step: 1,
                onChange: (event) => {
                  let value = parseInt(event.target.value);
                  if (!isNaN(value)) {
                    value = Math.min(Math.max(value, 0), 23); // Asegurar que esté entre 0 y 23
                  } else {
                    value = 0; // En caso de que no sea un número válido, establecer como 0
                  }
                  setHoraBase(value);
                },
              }}
              error={errors.horaBase}
            //helperText={errors.instanceResizeRespaldo ? "Este campo es obligatorio" : ""}
            />
            <TextField size="small" value={horaResize} type="number" required id="hora-resize" label="Hora Resize" style={{ width: 223 }}
              inputProps={{
                min: 0,
                max: 24,
                step: 1,
                onChange: (event) => {
                  let value = parseInt(event.target.value);
                  if (!isNaN(value)) {
                    value = Math.min(Math.max(value, 0), 23); // el numero esta entre 0 y 23
                  } else {
                    value = 0; // si no es un número válido, asigna 0
                  }
                  setHoraResize(value);
                },
              }}
              error={errors.horaResize}
            />
            <TextField
              size="small"
              value={dias}
              required
              id="dias"
              label="Dias"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDias(event.target.value);
              }}
              onBlur={handleBlur}
              error={errors.dias}
              helperText={errors.dias ? "Error. Debe ser [1,2,3,4,5,6,0]" : ""}
            />
            <TextField size="small" value={comentario} id="Comentario" label="Comentario"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setComentario(event.target.value);
              }} />
            <TextField size="small" value={feActualizacion} disabled id="fe-actualizacion" label="Fecha Actualizacion"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFeActualizacion(event.target.value);
              }}
            />
            <TextField size="small" value={feCrecion} disabled id="fe-crecion" label="Fecha Crecion"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFeCrecion(event.target.value);
              }}
            />
            <Stack
              justifyContent="right"
              direction="row" spacing={1} sx={{ border: 0, p: 1 }}>
              {errorMessage && (
                <Typography color="error" variant="body2" sx={{ ml: 2, alignSelf: 'center' }}>
                  {errorMessage}
                </Typography>
              )}
              <Button disableElevation onClick={() => props.handleCloseMonitor(false)} >
                Cancelar
              </Button>
              <Button variant="contained" disableElevation onClick={handleAccept}>
                Aceptar
              </Button>
            </Stack>

          </Box>

        }
      </Box>
    </Fragment>
  );
}

export default ViewNewEdit;

const instanceOptions = [
  { label: 't3.large -8GB 2CPU', value: 't3.large' },
  { label: 't3.xlarge -16GB 4CPU', value: 't3.xlarge' },
  { label: 't3.2xlarge-32GB 8CPU', value: 't3.2xlarge' },
  { label: 'm6i.large- 8GB 2CPU', value: 'm6i.large' },
  { label: 'm6i.xlarge-16GB 4CPU', value: 'm6i.xlarge' },
  { label: 'm6i.2xlarge-32GB 8CPU', value: 'm6i.2xlarge' },
  { label: 'm6i.4xlarge-64GB 16CPU', value: 'm6i.4xlarge' },
  { label: 'm7a.large-8GB 2CPU', value: 'm7a.large' },
  { label: 'm7a.xlarge-16GB 4CPU', value: 'm7a.xlarge' },
  { label: 'm7a.2xlarge-32GB 8CPU', value: 'm7a.2xlarge' },
  { label: 'm7a.4xlarge-64GB 16CPU', value: 'm7a.4xlarge' },
  { label: 'm7i.large-8GB 2CPU', value: 'm7i.large' },
  { label: 'm7i.xlarge-16GB 4CPU', value: 'm7i.xlarge' },
  { label: 'm7i.2xlarge-32GB 8CPU', value: 'm7i.2xlarge' },
  { label: 'm7i.4xlarge-64GB 16CPU', value: 'm7i.4xlarge' },
  { label: 'm7i-flex.large-8GB 2CPU', value: 'm7i-flex.large' },
  { label: 'm7i-flex.xlarge-16GB 4CPU', value: 'm7i-flex.xlarge' },
  { label: 'm7i-flex.2xlarge-32GB 8CPU', value: 'm7i-flex.2xlarge' },
  { label: 'm7i-flex.4xlarge-64GB 16CPU', value: 'm7i-flex.4xlarge' },
  { label: 'r5a.large-16GB 2CPU', value: 'r5a.large' },
  { label: 'r5a.xlarge-32GB 4CPU', value: 'r5a.xlarge' },
  { label: 'r5a.2xlarge-64GB 8CPU', value: 'r5a.2xlarge' },
  { label: 'r5a.4xlarge-128GB 16CPU', value: 'r5a.4xlarge' },
  { label: 'r6i.xlarge-32GB 4CPU', value: 'r6i.xlarge' },
  { label: 'r6i.2xlarge-64GB 8CPU', value: 'r6i.2xlarge' },
  { label: 'r6i.4xlarge-128GB 16CPU', value: 'r6i.4xlarge' },
  { label: 'r7a.large-16GB 2CPU', value: 'r7a.large' },
  { label: 'r7a.xlarge-32GB 4CPU', value: 'r7a.xlarge' },
  { label: 'r7a.2xlarge-64GB 8CPU', value: 'r7a.2xlarge' },
  { label: 'r7a.4xlarge-128GB 16CPU', value: 'r7a.4xlarge' },
  { label: 'r7i.xlarge-32GB 4CPU', value: 'r7i.xlarge' },
  { label: 'r7i.2xlarge-64GB 8CPU', value: 'r7i.2xlarge' },
  { label: 'r7i.4xlarge-128GB 16CPU', value: 'r7i.4xlarge' },
];

