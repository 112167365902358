import React, { useMemo } from 'react';
import { Box, Button, FormControl, InputLabel, FormHelperText } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MaterialReactTable, type MRT_ColumnDef, } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AppLoading from '../app/AppLoading.tsx';
import TextField from '@mui/material/TextField';
import fetchData from '../app/FetchData.js';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";
const context = 'fe-recepcion';
const Example = (props:any) => {
  const [email, setEmail] = React.useState("");
  const [subject, setSubject] = React.useState("");
  const [fechaIni, setFechaIni] = React.useState<Dayjs | null>(dayjs(new Date()));    
  const [fechaFin, setFechaFin] = React.useState<Dayjs | null>(dayjs(new Date()));
  const [subjectError, setSubjectError] = React.useState(false);  
  
  const [data, setData] = React.useState([]);
  const [blocking, setBlocking] = React.useState(false);
  const [msgAction, setMsgAction] = React.useState(context);

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const countRows = useMemo(
    () => data.reduce((acc, curr) => acc + 1, 0),
    [],
  );

  const columns = useMemo(
    () => [
      { header: 'id', accessorKey: 'id', },
      { header: 'message id', accessorKey: 'id_mensaje', },
      { header: 'error', accessorKey: 'error', },  
      { header: 'mail_from', accessorKey: 'mail_from', },
      { header: 'mail_to', accessorKey: 'mail_to', },
      { header: 'subject', accessorKey: 'subject', },            
      { header: 'origen', accessorKey: 'origen', },            
      { header: 'tipo_notificacion', accessorKey: 'tipo_notificacion', },            
      { header: 'fecha_recibido', accessorKey: 'fecha_recibido', },        
      { header: 'attachment', accessorKey: 'attachment', },  
      { header: 'cc', accessorKey: 'cc', },  
    ],[countRows]
  );

  const customTableContainerProps = {
    sx: { maxHeight: props.windowHeight-262 },
  };

  const handleButtonClick = async () => {
    if (subject === "") {
      setSubjectError(true);
      return;
    }
    setSubjectError(false);
    
    if (email || subject) {
      const dateIni = fechaIni ? fechaIni.toDate().getTime() : null;
      const dateFin = fechaFin ? fechaFin.toDate().getTime() : null;

      const params = {
        action: "get",
        object: {
          email, subject, fechaIni: dateIni, fechaFin: dateFin
        }
      };

      setBlocking(true);
      setMsgAction("search");

      let resp;
      try {
        resp = await fetchData(url, context, params);
      } catch (error) {
        console.error("Error fetching data: ", error);
        resp = { rows: [] }; // Default to empty data in case of error
      }

      setBlocking(false);
      setData(resp.rows);
    } else {
      console.log("Debe de digitar email o subject");
    }
  };

  return (    
    <React.Fragment>     
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={[]}>   
          <TextField value={email} id="email" label="e-mail"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
            }}/>
          <FormControl error={subjectError} required>
            <TextField
            required id="asunto"
              value={subject}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSubject(event.target.value);
              }}
              label={subjectError ? "Campo requerido" : "Asunto"}
              error={subjectError}
              aria-describedby="asunto-error-text"
            />
          </FormControl>
          <DateTimePicker 
            label={'inicial timestap'} 
            //views={['month', 'year']} 
            onChange={(newValue) => setFechaIni(newValue)}
            value={fechaIni}
            />   
          <DateTimePicker 
            label={'final timestap'} 
            //views={['month', 'year']} 
            onChange={(newValue) => setFechaFin(newValue)}
            value={fechaFin}
            />             
          <Button variant="contained" disableElevation
            onClick={async () => {
                if(email || subject){
                  let dateIni = fechaIni.$d;
                  let dateFin = fechaFin.$d;
                  const params = {
                      action: "get",
                      object:{
                          email, subject, fechaIni: dateIni.getTime(), fechaFin:dateFin.getTime()
                      }                    
                  };                                                         
                  setBlocking(true);
                  setMsgAction("search");
                  let resp = await fetchData(url, context, params);
                  setBlocking(false);
                  setData(resp.rows);
                }else{
                  console.log("debe de digitar email o subject")
                }
                //console.log("result", resp);             
            }}
            Button variant="contained" disableElevation onClick={handleButtonClick}>
            Get Recepcion
          </Button>
        </DemoContainer>
      </LocalizationProvider>
      {
      blocking ?  
      <AppLoading {...props} titleCustom={msgAction}/>
        :
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnResizing enableGrouping enableStickyHeader enableStickyFooter
          initialState={{ density: 'compact',expanded: true, pagination: { pageIndex: 0, pageSize: 100 }, }}
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          muiTableContainerProps= {customTableContainerProps}
          renderTopToolbarCustomActions= {({ table }) => (
            <Box sx={{ display: 'flex',gap: '16px',padding: '4px',flexWrap: 'wrap',}}>
              <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}> Export All Data</Button>
            </Box>
          )}
        />
      }
    </React.Fragment>
  );
};

/*function validateFiels(...arrayFiels){
  for(let objField of arrayFiels){
    if(objField.value && objField.validator == "null"){

    }
  }
  return true;
}*/

export default Example;
