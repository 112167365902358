import React from 'react';
import TmEc2Aws from '../app-views/TmEc2Aws.tsx';
import TmRdsAws from '../app-views/TmRdsAws.tsx';
import TmEc2AwsMetricas from '../app-views-complex/Ec2AwsMetricas/TmEc2AwsMetricas.tsx';
import TmConcurrencia from '../app-views/TmConcurrencia.tsx';
import TmEc2Resize from '../app-views-complex/Ec2AwsResize/TmEc2Resize.tsx';
import TmEc2ResizeLegacy from '../app-views/TmEc2ResizeLegacy.tsx';
import TmRecepcionFe from '../app-views/TmRecepcionFe.tsx';
import TmInformes from '../app-views-complex/Informes/TmInformes.tsx';
import TmEc2StopStart from '../app-views-complex/Ec2AwsStartStop/TmEc2StartStop.tsx';
import TmFrontStartStop from '../app-views-complex/Ec2frontStarStop/TmFrontStartStop.tsx';
import TmDisponibilidad from '../app-views-complex/disponibilidad/TmDisponibilidad.tsx';
import HomePest from './HomePest.tsx';

export default function View(props: any) {    
    /** implemmentacion para el responsive */          
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
    const [windowHeight, setWindowHeight] = React.useState(window.innerHeight)
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth)
        setWindowHeight(window.innerHeight)
    }
    React.useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions)
        }
    }, []);
    /** fin 
     * implemmentacion para el responsive */    
    const view = getView(props.dataView.viewfront, props, windowWidth, windowHeight);          
    return (
        <React.Fragment>                
            {view}
        </React.Fragment>          
    );    
}

/** inplmentacion de las redirecciones para ser mejor*/ 
const getView = (viewFront, props, windowWidth, windowHeight) => {    
    let view = <h1>error en las redirecciones</h1>;
    switch (viewFront) {
        case 'TmEc2Aws':
            view = <TmEc2Aws {...props} windowWidth={windowWidth} windowHeight={windowHeight}/>;
            break;
        case 'TmEc2AwsMetricas':
            view =  <TmEc2AwsMetricas {...props} windowWidth={windowWidth} windowHeight={windowHeight}/>;
            break;
        case 'TableMateTestRender':
            view =  <HomePest/>;
            break;
        case 'TmRdsAws':
            view = <TmRdsAws {...props} windowWidth={windowWidth} windowHeight={windowHeight}/>;
            break;
        case 'TmConcurrencia':
            view = <TmConcurrencia {...props} windowWidth={windowWidth} windowHeight={windowHeight}/>;
            break;
        case 'TmEc2Resize':
            view = <TmEc2Resize {...props} windowWidth={windowWidth} windowHeight={windowHeight}/>;
            break;
        case 'TmEc2ResizeLegacy':
            view = <TmEc2ResizeLegacy {...props} windowWidth={windowWidth} windowHeight={windowHeight}/>;
            break;
        case 'TmEc2StartStop':
            view = <TmEc2StopStart {...props} windowWidth={windowWidth} windowHeight={windowHeight}/>;
            break;
        case 'TmDisponibilidad':
            view = <TmDisponibilidad {...props} windowWidth={windowWidth} windowHeight={windowHeight}/>;
            break;
        case 'TmRecepcionFe':
            view = <TmRecepcionFe {...props} windowWidth={windowWidth} windowHeight={windowHeight}/>;
            break;
        case 'TmInformes':
            view = <TmInformes {...props} windowWidth={windowWidth} windowHeight={windowHeight}/>;
            break;
        case 'TmFrontStartStop':
            view = <TmFrontStartStop {...props} windowWidth={windowWidth} windowHeight={windowHeight}/>;
            break;
        default:
            view = <HomePest/>;
    }
    return view;
}

