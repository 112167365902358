import React, { useRef, useEffect } from "react";
import { createChart } from 'lightweight-charts';

const hm = new Map();

const View = (props: any) => {

    const [alignment, setAlignment] = React.useState("");   
    const [data, setData] = React.useState(props || []);  

    useEffect(() => {
      hm.set(props.alignment, props.data);
      setAlignment(props.alignment); 
      setData(props.data);
      responseData(props.alignment, props.data)
    }, [props.data]);

    const responseData = async (alignment: string, data: any) => {

          if (!props.data || !props.data.infoEc2 || !props.data.infoEc2.rows) {
              console.error('Data is missing or malformed');
              return;
          }
          const dataTransformada = props.data.infoEc2.rows.map(row => ({
            time: parseInt(row.t) - (3600 * 5),  
            value: parseFloat(row.r), 
          }));

          setData(dataTransformada)        
    }
    const handleChange = (
      event: React.MouseEvent<HTMLElement>,
      newAlignment: string,
    ) => {              
        if (newAlignment) {
            setData(data);
            setAlignment(newAlignment);
            props.onAlignmentChange(newAlignment); // Notify parent (TabsDinamicas) of the alignment change
        }
    };

    return (        
        <React.Fragment>
          {data && <ApexLine vtDataRender={JSON.stringify(data)} />}  
        </React.Fragment>
    );
}

function ApexLine(props: any) {
  const chartContainerRef = useRef();

  useEffect(() => {
      const chartOptions = {
          layout: {
              textColor: 'black',
              background: { type: 'solid', color: 'white' }
          },
          timeScale: {
              borderColor: '#d1d4dc',
              timeVisible: true,
              secondsVisible: false,
              tickMarkFormatter: (time, tickMarkType, locale) => {
                  const date = new Date((time + (3600 * 5)) * 1000);
                  return date.toLocaleDateString('es-ES', { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });
              },
          },
      };

      const chart = createChart(chartContainerRef.current, chartOptions);
      const lineSeries = chart.addAreaSeries({ lineColor: '#2962FF', topColor: '#2962FF', bottomColor: 'rgba(41, 98, 255, 0.28)' });

      // Parse the stringified data
      let data;
      try {
          data = JSON.parse(props.vtDataRender);
      } catch (e) {
          console.error('Error parsing JSON', e);
          data = [];
      }

      // Ensure data is an array
      if (Array.isArray(data)) {
          // Filtrar datos para eliminar duplicados si es necesario
          const uniqueData = data.filter((value, index, self) =>
              index === self.findIndex((t) => (t.time === value.time))
          );
          // Ordenar datos por el campo 'time'
          const sortedData = uniqueData.sort((a, b) => a.time - b.time);

          lineSeries.setData(sortedData);
      } else {
          console.error('Expected data to be an array, but got:', data);
      }

      return () => {
          chart.remove();
      };
  }, [props.vtDataRender]);

  return <div ref={chartContainerRef} style={{ backgroundColor: '#123132', height: '600px', width: '1500px', flex: 1, display:'flex'}} />;
}

export default View;