import React, { useEffect, useState, useRef } from 'react';
import { Tabs, Tab, Box, ToggleButton, ToggleButtonGroup, Grid, Typography } from '@mui/material';
import fetchData from "../../app/FetchData";
import { createChart } from 'lightweight-charts';
import RenderDinamico from './RenderDinamico.tsx';
import CircularProgress from '@mui/material/CircularProgress';

const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";
const hm = new Map();
let instance_name = '';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const TabsDinamicas = (props: any) => {
    const [value, setValue] = useState(0);
    const [alignment, setAlignment] = useState("diario");
    const [data, setData] = useState<any[]>([]);
    const [dataUsers, setDataUser] = useState();
    const [dataDiscos, setDataDiscos] = useState();
    const [dataInstance, setDataInstance] = useState('');
    const [maxCpu, setMaxCpu] = useState(0);
    const [maxRam, setMaxRam] = useState(0);
    const [avgCpu, setAvgCpu] = useState(0);
    const [avgRam, setAvgRam] = useState(0);
    const [availability, setAvailability] = useState(100);
    
    const dataObj = props.data.instanciasCliente;
    const dataUsuDA = props.dataUser;

    useEffect(() => {
        if (dataObj?.rows[value]?.instance_id) {
            const instanceId = dataObj.rows[value].instance_id;
            fetchForTabs(instanceId, alignment);
        }
    }, [value, alignment]);

    const fetchForTabs = async (instanceId: string, period: string) => {
        if (!instanceId) {
            console.error('Instance id no encontrado');
            return;
        }
        
        let maxCpu = 0;
        let maxRam = 0;
        let sumCpu = 0;
        let sumRam = 0;
        let count = 0; 
        let avgCpu = 0;
        let avgRam = 0;

        const now = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
        const oneDay = 86400;
        const oneWeek = 7 * oneDay;
        const oneMonth = 30 * oneDay;

        let timeRange = now - oneDay;
        if (period === 'semanal') {
            timeRange = now - oneWeek;
        } else if (period === 'mensual') {
            timeRange = now - oneMonth;
        }

        const params = {
            objeto: instanceId,
            action: 'get-data-for-instance',
            timeFilter: timeRange * 1000,
        };

        try {
            const result = await fetchData(url, "ec2-disponibilidad", params);
            const infoDiscosEc2 = result.informacionDiscos.rows;

            let timeAnterior = 0;
            let timeIndisponibildad = 0;
            let totalIndisponibilidad = 0;
            for(let objRow of result.infoEc2.rows){       
            
            let timeAct = (parseInt(objRow.t) - (3600*5));
            if(timeAnterior > 0){
                if((timeAct - timeAnterior) >= 300){
                timeIndisponibildad = ((timeAct - timeAnterior) - 300) / 60
                totalIndisponibilidad += timeIndisponibildad;
                }
            }
            timeAnterior = timeAct

            /* Validacion Datos para Resumen de Disponibilidad */
            let cpuValue = parseFloat(objRow.c);
            let ramValue = parseFloat(objRow.r);

            if(cpuValue > maxCpu) maxCpu = cpuValue;
            if(ramValue > maxRam) maxRam = ramValue;

            sumCpu += cpuValue;
            sumRam += ramValue;

            count++; // Incrementamos el contador de filas
            }

             avgCpu = count > 0 ? parseFloat((sumCpu / count).toFixed(2)) : 0;
             avgRam = count > 0 ? parseFloat((sumRam / count).toFixed(2)) : 0;
        
            const timeDisponibleMes = 60 * 24 * 30;
            const disponibilidadTotal = timeDisponibleMes - totalIndisponibilidad;
            const availabilityPercentage = (disponibilidadTotal / timeDisponibleMes) * 100;

            hm.set(period, result);
            setData(result);
            instance_name = result.informacionEc2.rows.instance_name
            setDataUser(dataUsuDA);
            setDataInstance(result.informacionEc2.rows[0]);
            setDataDiscos(infoDiscosEc2)
            setAvailability(availabilityPercentage);
            setMaxCpu(maxCpu);
            setMaxRam(maxRam);
            setAvgCpu(avgCpu);
            setAvgRam(avgRam);

        } catch (error) {
            console.log('Error al obtener los datos:', error);
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setAlignment('diario');
        // Actualiza el instanceId cuando cambias el tab
        if (dataObj?.rows[newValue]?.instance_id) {
            const instanceId = dataObj?.rows[newValue]?.instance_id;
            fetchForTabs(instanceId, 'diario');
        }
    };

    const handleAlignmentChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string
    ) => {
        if (newAlignment) {
            setAlignment(newAlignment);
            // Asegúrate de que instanceId está definido
            if (dataObj?.rows[value]?.instance_id) {
                const instanceId = dataObj?.rows[value]?.instance_id;
                fetchForTabs(instanceId, newAlignment);
            }
        }
    };

    return (
      <React.Fragment>      
        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 800 }}>
          <Tabs orientation='vertical' variant='scrollable' value={value} onChange={handleTabChange} aria-label='Vertical tabs example' sx={{borderRight: 1, borderColor: 'divider'}}>  
            {dataObj?.rows.map((row, index) => (
                <Tab label= {`${row.instance_id} (${row.producto})`} key={index} {...a11yProps(index)}
                
                />
              
            ))}
          </Tabs>
            <Box sx={{ p: 5 }}>
            <RenderDinamico data={data} dataUser={dataUsers} dataDiscos={dataDiscos} alignment={alignment} onAlignmentChange={handleAlignmentChange} 
            columns={[
                { accessorKey: 'username', header: 'username' },
                { accessorKey: 'groupname', header: 'groupname' },
                ]}

            columnsDiscos={[
                { accessorKey: 'disk', header: 'Disco' },
                { accessorKey: 'total_size', header: 'Tamaño (GB)' },
                { accessorKey: 'used_space', header: 'Espacio en Uso (GB)' },
                { accessorKey: 'free_percent', header: '% Libre' },
                ]}
            />
            </Box>
            <Box sx={{ p:5 }}>
                <Grid container spacing={2} direction="row" justifyContent='flex-start' alignItems="stretch">
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography id="modal-modal-ec2info-name" variant="h6" component="h1" sx={{ mt: 10, fontWeight: 'bold' }}>
                            Resumen Instancia Ec2:
                        </Typography>
                        <Typography id="modal-modal-ec2info-name" variant="h6" component="h2" sx={{ mt: 2, fontWeight: 'bold' }}>
                            <div>{dataInstance.instance_name}</div>
                            <div> InstanceType : {dataInstance.instance_type} </div>
                            <div> Core Cpu: {dataInstance.default_vcpus} </div>
                            <div> Memory : {dataInstance.memory_info} GB </div>
                            <div> Cliente : {dataInstance.cliente}</div>
                        </Typography>
                    </Box>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2}}>
                  <CircularProgress variant="determinate" color={"success"} size="110px" thickness={3} value={availability} />
                  <Box sx={{ position: 'absolute' }}>
                    <Typography variant="h6" component="div" color="textSecondary">{`${availability.toFixed(2)}%`}</Typography>
                  </Box>
                </Box>
                <Grid container spacing={0} direction="row" justifyContent='flex-start' alignItems="stretch">
                <Box sx={{ flexGrow: 1 }}>
                <Typography id="modal-modal-ec2info-name" variant="h6" component="h1" sx={{ mt: 10, fontWeight: 'bold' }}>
                    Resumen de Recursos (Cpu, Memoria):
                </Typography>
                
                <Typography id="modal-modal-resumen" variant="h6" component="h2" sx={{ mt: 3, fontWeight: 'normal'}}>
                    <b>Cpu Máxima:</b> {maxCpu}<br></br>
                    <b>Promedio Cpu:</b> {avgCpu}<br></br>
                    <b>Ram Máxima:</b> {maxRam}<br></br>
                    <b>Ram Máxima:</b> {avgRam}<br></br>
                </Typography>
                </Box>
                </Grid>
            </Box>
        </Box>      
      </React.Fragment>
    );
}

export default TabsDinamicas;