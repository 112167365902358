import { Routes, Route } from "react-router-dom";
import SignIn from "../login/signIn.js";
import AppNav from '../app/AppNav.tsx';
// import { useNavigate } from "react-router-dom";

export default function AppRooute(props) {
  // const history = useNavigate();
 
  // const handleGoHome = () => {
  //   history("App"); // New line
  // };
  console.log("props.....", props);
  return (    
      <Routes>
        <Route path="App" element={<AppNav/>} />       
        <Route path="/" element={<SignIn/>}></Route>
      </Routes>
  );
}