import React, { useMemo, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import fetchData from '../app/FetchData.js';
import { /*blue, purple,*/ grey,} from '@mui/material/colors';
import AppLoading from '../app/AppLoading.tsx';

const csvConfig = mkConfig({ fieldSeparator: ',', decimalSeparator: '.', useKeysAsHeaders: true, });
interface TabPanelProps { children?: React.ReactNode;index: number; value: number;}
const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";
const Example = (props:any) => {
  
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [dataAgente, setDataAgente] = React.useState([]);
  const [dataAgenteTs, setDataAgenteTs] = React.useState([]);
  const [dataAgenteTsUsu, setDataAgenteTsUsu] = React.useState([]);
  const [dataConnErp, setDataConnErp] = React.useState([]);
  const [blocking, setBlocking] = React.useState(false);

  const handleChange =  async (event: React.SyntheticEvent, newValue: number) => {
    if(newValue === 1 && dataAgente.length === 0){
      let dt = new Date();
      let time = dt.getTime()-(1000*60*15);
      setBlocking(true);
      let resp = await fetchData(url, "ec2-disponibilidad", {"action":"get-by-time", time});
      setBlocking(false);
      setDataAgente(resp.rows);
    }else if(newValue === 2 && dataAgenteTs.length === 0){
      setBlocking(true);
      let resp = await fetchData(url, "ec2-get-agente", {"action":"ec2-aws-agente-ts"});
      setBlocking(false);
      setDataAgenteTs(resp.rows);
    }else if(newValue === 3 && dataAgenteTsUsu.length === 0){
      setBlocking(true);
      let resp = await fetchData(url, "ec2-get-agente", {"action":"ec2-aws-agente-ts-usu"});
      setBlocking(false);
      setDataAgenteTsUsu(resp.rows);
    }else if(newValue === 4 && dataConnErp.length === 0){
      setBlocking(true);
      let resp = await fetchData(url, "ec2-aws", {"action":"ec2-aws-conn-erp"});
      setBlocking(false);
      setDataConnErp(resp.rows);
    }
    setValue(newValue);  
  } 

  useEffect(() => {        
    const catData = async () => {           
      setBlocking(true);
      const data = await fetchData(url, "ec2-aws", {"action":"get"});
      setData(data.rows);
      setBlocking(false)
    };      
    catData();              
  }, []);

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const countRows = useMemo(
    () => data.reduce((acc, curr) => acc + 1, 0),
    [],
  );
  const columns = useMemo(
    () => [
      {  header: 'cliente', accessorKey: 'cliente', },
      {  header: 'cuenta',  accessorKey: 'cuenta', },
      {  header: 'instance_id',  accessorKey: 'instance_id', },
      {  header: 'name', accessorKey: 'instance_name', },
      {  header: 'type', accessorKey: 'instance_type', },      
      {  header: 'ip_privada', accessorKey: 'ip_privada', },      
      {  header: 'ip_publica', accessorKey: 'ip_publica', },
      {  header: 'pais', accessorKey: 'pais', },
      {  header: 'producto', accessorKey: 'producto', },
      {  header: 'despliegue', accessorKey: 'tipo_despliegue', },
      {  header: 'tipo-producto', accessorKey: 'tipo_producto', },
      {  header: 'estado', accessorKey: 'estado', },
      {  header: 'default_cores', accessorKey: 'default_cores', },
      {  header: 'default_vcpus', accessorKey: 'default_vcpus', },
      {  header: 'memory_info', accessorKey: 'memory_info', },
         
    ],[countRows]
  );
  const columnsAgente = useMemo(
    () => [
      {  header: 'cliente', accessorKey: 'cliente', },
      {  header: 'cuenta',  accessorKey: 'cuenta', },
      {  header: 'instance_id',  accessorKey: 'instance_id', },  
      {  header: 'name', accessorKey: 'instance_name', },    
      {  header: 'Agente', accessorKey: 'agente', filterVariant: 'checkbox' 
        , Cell: ({ cell }) => 
            // cell.getValue() === 'true' ? 'Activo' : 'Inactivo'        
            cell.getValue()
        , accessorFn : (originalRow) => (originalRow.agente_instalado === 'S' ? 'true' : 'false'), //must be strings
      },        
      {  header: 'version', accessorKey: 'version_agente', },  
      {  header: 'type', accessorKey: 'instance_type', },  
      {  header: 'producto', accessorKey: 'producto', },     
      {  header: 'tipo-producto', accessorKey: 'tipo_producto', }, 
      {  header: 'estado', accessorKey: 'estado', filterFn: 'equals',
        filterSelectOptions: [ { label: 'stopped', value: 'stopped' }, { label: 'running', value: 'running' } ],
        filterVariant: 'select',
      },
    ],[]
  );  
  const columnsConnErp = useMemo(
    () => [
      {  header: 'cuenta',  accessorKey: 'cuenta', },
      {  header: 'instance_id',  accessorKey: 'instance_id', },  
      {  header: 'instance_type',  accessorKey: 'instance_type', },  
      {  header: 'name', accessorKey: 'instance_name', },    
      {  header: 'type', accessorKey: 'instance_type', },  
      {  header: 'version_producto', accessorKey: 'version_producto', },      
      {  header: 'agente', 
        accessorKey: 'existencia_en_clientes_siesa',       
         },
      {  header: 'rds', accessorKey: 'name_rds', },
      {  header: 'database', accessorKey: 'name_database', },
      {  header: 'producto', accessorKey: 'producto', },
      {  header: 'ip_publica', accessorKey: 'ip_publica', },
      {  header: 'ip_privada', accessorKey: 'ip_privada', },
    ],[]
  );  

  const customTableContainerProps = {
    sx: { maxHeight: props.windowHeight-250 },
  };
  
  return (
    <React.Fragment> 
      <Box sx={{ width: '100%', bgcolor: grey[100] }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
            sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
            <Tab label="Ec2 Siesa" {...a11yProps(0)} />
            <Tab label="Ec2 Agente" {...a11yProps(1)} />
            <Tab label="Ec2 Ts-Plus" {...a11yProps(2)} />
            <Tab label="Ts-Plus Usuarios" {...a11yProps(3)} />
            <Tab label="Conn Erp" {...a11yProps(4)} />
            <Tab label="Conn AG" {...a11yProps(5)} />
            <Tab label="Conn Nómina" {...a11yProps(6)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} sx={{ padding:0 }} >
          {
            blocking ?  
              <AppLoading {...props}/>
              :
            <MaterialReactTable
              columns={columns} 
              data={data}
              enableColumnResizing
              enableGrouping
              enableStickyHeader
              enableStickyFooter
              initialState={{
                density: 'compact',
                expanded: true, //expand all groups by default
                // grouping: ['cuenta'], //an array of columns to group by by default (can be multiple)
                pagination: { pageIndex: 0, pageSize: 100 },
                // sorting: [{ id: 'state', desc: false }], //sort by state by default
              }}
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              muiTableContainerProps={customTableContainerProps}
              renderTopToolbarCustomActions= {({ table }) => (
                <Box sx={{ display: 'flex', gap: '16px', padding: '4px', flexWrap: 'wrap',  }} >
                  <Button onClick={handleExportData} startIcon={<FileDownloadIcon />} >Export All Data </Button> 
                </Box>
              )}
            />
          }
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} sx={{ padding:0 }} >          
          {
          blocking ?  
            <AppLoading {...props}/>
          :
            <MaterialReactTable
              columns={columnsAgente}
              data={dataAgente}
              enableColumnResizing   enableGrouping   enableStickyHeader   enableStickyFooter
              initialState={{ density: 'compact',  expanded: true, pagination: { pageIndex: 0, pageSize: 100 },
              }}
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              muiTableContainerProps={customTableContainerProps}
              renderTopToolbarCustomActions= {({ table }) => (
                <Box sx={{ display: 'flex', gap: '16px', padding: '4px', flexWrap: 'wrap',}} >
                  <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}> Export All Data</Button>
                </Box>
              )}
            />
          }
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2} sx={{ padding:0 }} >
        {
          blocking ?  
            <AppLoading {...props}/>
          :
          <MaterialReactTable
              columns={columnsAgente}
              data={dataAgenteTs}
              enableColumnResizing   enableGrouping   enableStickyHeader   enableStickyFooter
              initialState={{ density: 'compact',  expanded: true, pagination: { pageIndex: 0, pageSize: 100 },
              }}
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              muiTableContainerProps={customTableContainerProps}
              renderTopToolbarCustomActions= {({ table }) => (
                <Box sx={{ display: 'flex', gap: '16px', padding: '4px', flexWrap: 'wrap',}} >
                  <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}> Export All Data</Button>
                </Box>
              )}
            />
        }
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3} sx={{ padding:0 }} >
        {
          blocking ?  
            <AppLoading {...props}/>
          :
            <MaterialReactTable
              columns={columnsAgente}
              data={dataAgenteTsUsu}
              enableColumnResizing   enableGrouping   enableStickyHeader   enableStickyFooter
              initialState={{ density: 'compact',  expanded: true, pagination: { pageIndex: 0, pageSize: 100 },
              }}
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              muiTableContainerProps={customTableContainerProps}
              renderTopToolbarCustomActions= {({ table }) => (
                <Box sx={{ display: 'flex', gap: '16px', padding: '4px', flexWrap: 'wrap',}} >
                  <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}> Export All Data</Button>
                </Box>
              )}
            />
          }          
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4} sx={{ padding:0 }} >
        {
          blocking ?  
            <AppLoading {...props}/>
          :
          <MaterialReactTable
              columns={columnsConnErp}
              data={dataConnErp}
              enableColumnResizing   enableGrouping   enableStickyHeader   enableStickyFooter
              initialState={{ density: 'compact',  expanded: true, pagination: { pageIndex: 0, pageSize: 100 },
              }}
              muiToolbarAlertBannerChipProps={{ color: 'primary' }}
              muiTableContainerProps={customTableContainerProps}
              renderTopToolbarCustomActions= {({ table }) => (
                <Box sx={{ display: 'flex', gap: '16px', padding: '4px', flexWrap: 'wrap',}} >
                  <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}> Export All Data</Button>
                </Box>
              )}
          />
              }
        </CustomTabPanel>
      </Box>
    </React.Fragment>
  );
};

function a11yProps(index: number) {  
  return {  id: `simple-tab-${index}`,  'aria-controls': `simple-tabpanel-${index}`, }; 
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
         {children}
        </Box>
      )}
    </div>
  );
};

export default Example;