import React, { useMemo, useEffect } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MaterialReactTable, type MRT_ColumnDef, } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AppLoading from '../../app/AppLoading.tsx';
import fetchData from '../../app/FetchData.js';
import ViewData from './ViewData.tsx';
import Modal from '@mui/material/Modal';

const csvConfig = mkConfig({ fieldSeparator: ',', decimalSeparator: '.', useKeysAsHeaders: true,});
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 54,
  pl:2,  pr:2,  pt:0,  pb:2,
} 
const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";
const Example = (props:any) => {  
  // variables de page
  const [data, setData] = React.useState([]);
  const [blocking, setBlocking] = React.useState(false);
  const [rowSelect, setRowSelect] = React.useState("none");  
  const [openMonitor, setOpenMonitor] = React.useState(false);  
  const handleExportData = () => { const csv = generateCsv(csvConfig)(data); download(csvConfig)(csv);};  
  const handleOpenMonitor = (row) => { setOpenMonitor(true); setRowSelect(row); };
  const handleCloseMonitor = () => setOpenMonitor(false);
  

  useEffect(() => {        
    const catData = async () => {           
      setBlocking(true);
      const data = await fetchData(url, "ec2-disponibilidad", {"action":"get"});
      if(data.rows){
        setData(data.rows);
      }else{
        console.log("manejo de errores", data); 
      }
      setBlocking(false)
    };      
    catData();
  }, []);
  
  const columns = useMemo(
    () => [     
      {  header: 'cuenta',  accessorKey: 'cuenta', },
      {  header: 'database', accessorKey: 'name_database', },
      {  header: 'storage', accessorKey: 'tamano', },
      {  header: 'nro_instancias', accessorKey: 'nro_instancias', },
      {  header: 'nro_compañias', accessorKey: 'nro_companias', },
      {  header: 'usuarios_concurrentes', accessorKey: 'usuarios_concurrentes_db', },
      {  header: 'estado', accessorKey: 'estado', },
      {  header: 'rds_id',  accessorKey: 'rds_id', },
      {  header: 'rds_class', accessorKey: 'rds_class', },
      {  header: 'Engine', accessorKey: 'engine', },      
      {  header: 'ip_rds', accessorKey: 'ip_database', },              
    ],[]
  );

  const customTableContainerProps = {
    sx: { maxHeight: props.windowHeight-202 },
  };
  return (        
    <React.Fragment>          
      {
      blocking ?  
        <AppLoading {...props}/>
      :
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnResizing enableGrouping enableStickyHeader enableStickyFooter enableRowActions
          initialState={{density: 'compact', expanded: true, pagination: { pageIndex: 0, pageSize: 100 },}}
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          muiTableContainerProps= {customTableContainerProps}
          renderTopToolbarCustomActions= {({ table }) => (
            <Box sx={{display: 'flex', gap: '16px',padding: '4px',flexWrap: 'wrap',}}>
              <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}> Export All Data </Button>    
            </Box>
          )}        
          displayColumnDefOptions= {{ 'mrt-row-actions': {header: 'actions', size: 100, }, }}
          renderRowActions={({ row, table  }) => [          
            <Box>
              <IconButton onClick={() => handleOpenMonitor(row)}><AnalyticsIcon/></IconButton>
              <IconButton><PictureAsPdfIcon/></IconButton>
            </Box>
          ]}
        />
      }
      <Modal open={openMonitor} onClose={handleCloseMonitor} aria-labelledby="modal-modal-title"  aria-describedby="modal-modal-description" >     
       <Box sx={style}>
         <ViewData 
          row={rowSelect}         
          windowHeight={props.windowHeight}
          title={"Disponibilidad View"}
          ec2Info={rowSelect}
          handleCloseMonitor={handleCloseMonitor}
          />
        </Box>
      </Modal>
    </React.Fragment>
  );
};
export default Example;