import React, { useMemo, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Alert, useTheme, styled } from '@mui/material';
import AppLoading from '../../app/AppLoading.tsx';
import fetchData from '../../app/FetchData.js';

// Crear un Switch personalizado
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#1890ff' : '#1890ff',
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#39393D' : '#bfbfbf',
    boxSizing: 'border-box',
  },
}));

const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";
const context = 'ec2-aws';

const Example = (props) => {
  const theme = useTheme();

  const [data, setData] = React.useState([]);
  const [blocking, setBlocking] = React.useState(false);
  const [rowToAction, setRowToAction] = React.useState(null);
  const [openConfirmOff, setOpenConfirmOff] = React.useState(false);
  const [openConfirmOn, setOpenConfirmOn] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [instanceState, setInstanceState] = React.useState('');

  const handleOn = async () => {
    setOpenConfirmOn(false);
    setBlocking(true); // Mostrar loading

    const instanceId = rowToAction.original.instance_id;
    const cuenta = rowToAction.original.cuenta;
    const params = {
      action: 'start',
      object: {
        instanceId: instanceId,
        account: cuenta,
      },
    };

    await fetchData(url, context, params); // Ejecutar la acción
    await loadList(); // Refrescar la lista
    setInstanceState('running'); // Actualizar el estado de la instancia
    setBlocking(false); // Ocultar loading
    setShowMessage(true); // Mostrar mensaje
  };

  const handleOff = async () => {
    setOpenConfirmOff(false);
    setBlocking(true); // Mostrar loading

    const instanceId = rowToAction.original.instance_id;
    const cuenta = rowToAction.original.cuenta;
    const params = {
      action: 'stop',
      object: {
        instanceId: instanceId,
        account: cuenta,
      },
    };

    await fetchData(url, context, params); // Ejecutar la acción
    await loadList(); // Refrescar la lista
    setInstanceState('stopped'); // Actualizar el estado de la instancia
    setBlocking(false); // Ocultar loading
    setShowMessage(true); // Mostrar mensaje
  };

  const columns = useMemo(
    () => [
      { header: 'cliente', accessorKey: 'cliente' },
      { header: 'cuenta', accessorKey: 'cuenta' },
      { header: 'id Instance', accessorKey: 'instance_id' },
      { header: 'Instancia', accessorKey: 'instance_name' },
      { header: 'ip_privada', accessorKey: 'ip_privada' },
      { header: 'ip_publica', accessorKey: 'ip_publica' },
      { header: 'producto', accessorKey: 'producto' },
      { header: 'estado', accessorKey: 'estado' },
    ],
    []
  );

  const loadList = async () => {
    const params = {
      action: 'get',
    };
    setBlocking(true);
    const resp = await fetchData(url, context, params);
    setData(resp.rows);
    setBlocking(false);
  };

  useEffect(() => {
    loadList();
  }, []);

  return (
    <React.Fragment>
      {blocking ? (
        <AppLoading {...props} />
      ) : (
        <Box sx={{ position: 'relative', height: '100%' }}>
          {/* Snackbar para Mostrar el Estado de la Instancia */}
          <Snackbar
            open={showMessage}
            autoHideDuration={6000}
            onClose={() => setShowMessage(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              margin: 1,
              zIndex: 1200, // Asegúrate de que esté por encima de otros elementos
            }}
          >
            <Alert onClose={() => setShowMessage(false)} severity="info" sx={{ width: '100%' }}>
              {`Instancia: ${rowToAction?.original?.instance_id} Estado: ${instanceState}. Dar un tiempo de espera de 1 minuto`}
            </Alert>
          </Snackbar>

          <MaterialReactTable
            columns={columns}
            data={data}
            enableRowActions
            initialState={{
              density: 'compact',
              pagination: { pageIndex: 0, pageSize: 100 },
            }}
            muiTableContainerProps={{
              sx: {
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto',
              },
            }}
            renderRowActions={({ row }) => {
              const estado = row.original.estado;
              const isRunning = estado === 'running';

              return (
                <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <Typography>Off</Typography>
                  <AntSwitch
                    checked={isRunning}
                    onChange={() => {
                      setRowToAction(row);
                      if (!isRunning) setOpenConfirmOn(true);
                      else setOpenConfirmOff(true);
                    }}
                    color="default"
                  />
                  <Typography>On</Typography>
                </Box>
              );
            }}
          />
        </Box>
      )}

      {/* Diálogo para Apagar */}
      <Dialog open={openConfirmOff} onClose={() => setOpenConfirmOff(false)}>
        <DialogTitle>Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Se procederá a apagar la instancia: ${rowToAction?.original?.instance_id}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmOff(false)}>No</Button>
          <Button onClick={handleOff} color="primary">
            Sí
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para Encender */}
      <Dialog open={openConfirmOn} onClose={() => setOpenConfirmOn(false)}>
        <DialogTitle>Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Se procederá a encender la instancia: ${rowToAction?.original?.instance_id}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmOn(false)}>No</Button>
          <Button onClick={handleOn} color="primary">
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Example;
