import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState, Fragment } from "react";
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import fetchData from '../../app/FetchData.js';
import AppLoading from '../../app/AppLoading.tsx';

var cuentaSelectStopStart = "none";
var opcionesInstances = [];
const context = 'ec2-aws-start-stop';
const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";

const ViewNewEdit = (props: any) => {
  const [instanceId, setInstanceId] = useState(props.row.original ? props.row.original.instance_id : "");
  const [instancia, setInstance] = useState(props.row.original ? props.row.original.instance_name : "");
  const [instanceType, setInstanceType] = useState(props.row.original ? props.row.original.instance_type : "");
  const [horaApagado, setHoraApagado] = useState<number>(props.row.original ? props.row.original.hora_apagado : 0);
  const [horaEncendido, setHoraEncendido] = useState<number>(props.row.original ? props.row.original.hora_encendido : 0);
  const [dias, setDias] = useState(props.row.original ? props.row.original.dias : "");
  const [comentario, setComentario] = useState(props.row.original ? props.row.original.comentario : "");
  const [feActualizacion, setFeActualizacion] = useState(props.row.original ? props.row.original.fecha_creacion : "");
  const [feCrecion, setFeCrecion] = useState(props.row.original ? props.row.original.fecha_actualizacion : "");
  const [blocking, setBlocking] = useState(false);
  const [msgAction, setMsgAction] = useState(context);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [errors, setErrors] = useState({
    instancia: false,
    horaApagado: false,
    horaEncendido: false,
    dias: false,
  });

  console.log("propitos..........", props, cuentaSelectStopStart);

  if (opcionesInstances.length === 0 || props.cuentaSelect !== cuentaSelectStopStart) {
    opcionesInstances = [];
    for (let objInsEc2 of props.dataInstance) {
      opcionesInstances.push({ "title": objInsEc2.title });
    }
    cuentaSelectStopStart = props.cuentaSelect;
  }

  const handleAccept = async () => {
    const newErrors = {
      instancia: instancia === "",
      horaApagado: horaApagado === -1, // No se valida si están vacíos
      horaEncendido: horaEncendido === -1, // No se valida si están vacíos
      //dias: !validateDias(dias),
    };

    setErrors(newErrors);

    if (Object.values(newErrors).some(error => error)) {
      setErrorMessage("Los campos son requeridos.");
      setErrors(newErrors);
      return;
    } else {
      setErrorMessage(""); // Borra el mensaje de error si no hay errores en los campos requeridos
      setErrors({});
    }
  
    // Validar que horaBase y horaResize no sean iguales
    if (horaEncendido === horaApagado) {
      setErrorMessage("La hora base y resize no pueden ser iguales.");
      return;
    } else {
      setErrorMessage(""); // Borra el mensaje de error si las horas son diferentes
    }
  
    // Validar el formato del campo dias
    const isValid = validateDias(dias);
    if (!isValid) {
      setErrors(prevErrors => ({ ...prevErrors, dias: "Formato inválido. Debe ser [1,2,3,4]" }));
      setErrorMessage ("");
      return;
    } else {
      setErrors(prevErrors => ({ ...prevErrors, dias: "" })); // Borra el mensaje de error si dias cumple con el formato
      setErrorMessage(""); // Borra el mensaje de error si dias cumple con el formato
    }

    const params = {
      action: props.row.original ? "UPDATE" : "INSERT",
      object: {
        instanceId,
        instancia,
        instanceType,
        horaApagado,
        horaEncendido,
        dias,
        comentario,
        cuenta: props.cuentaSelect,
        feActualizacion: new Date(),
        feCrecion: props.row.original ? feCrecion : new Date(),
        id: props.row.original ? props.row.original.id : -1
      }
    };

    setBlocking(true);
    setMsgAction(props.row.original ? "Actualizando" : "Insertando");
    let resp = await fetchData(url, context, params);
    setBlocking(false);
    console.log("result", resp);
    props.handleCloseMonitor(true);
  };

  const validateDias = (value: string) => {
    const regex = /^\[([0-6](,[0-6])*)?\]$/;
    const isValidFormat = regex.test(value);
    if (!isValidFormat || value === '[]') return false;

    // Convert the string into an array of numbers and check for duplicates
    const numbers = value.slice(1, -1).split(',').map(Number);
    const uniqueNumbers = new Set(numbers);
    return numbers.length === uniqueNumbers.size;
  };

  const handleBlur = () => {
    const isValid = validateDias(dias);
    if (!isValid) {
      setErrors({ ...errors, dias: true });
    }
  };
  

  return (
    <Fragment>
      <Box sx={{ overflow: 'auto' }}>
        <Typography id="modal-modal-title"
          sx={{ mt: 2, mb: 1, alignItems: 'center', textAlign: 'center', fontSize: 24 }}>
          Instancia
        </Typography>
        {blocking ?
          <AppLoading {...props} titleCustom={msgAction} />
          :
          <Box id="modal-modal-description" component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
              width: 480,
              textAlign: 'center'
            }}
            noValidate
            autoComplete="off">
            <Container maxWidth="sm">
              <Autocomplete
                disablePortal
                style={{ width: 410 }}
                options={opcionesInstances.map((option) => option.title)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="instancia ec2"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-instance', // disable autocomplete and autofill
                    }}
                    error={errors.instancia}
                  />
                )}
                onChange={(event: any, newValue: string | null) => {
                  let objInstance = props.dataInstance.find(option => option.title === newValue);
                  if (objInstance) {
                    setInstanceId(objInstance.instance_id);
                    setInstance(objInstance.instance_name);
                    setInstanceType(objInstance.instance_type);
                    setFeActualizacion("");
                  }
                }}
              />
            </Container>
            <TextField size="small" value={instanceId} disabled id="instance-id" label="Instance Id" />
            <TextField size="small" value={instanceType} disabled id="instance-type" required label="Instance Type" />
            <TextField style={{ width: 410 }} value={instancia} disabled id="instancia" label="Instancia" />
            <TextField size="small" value={horaEncendido} type="number" required id="hora-encendido" label="Hora Encendido" style={{ width: 223 }}
              inputProps={{
                min: 0,
                max: 24,
                step: 1,
                onChange: (event) => {
                  let value = parseInt(event.target.value);
                  if (!isNaN(value)) {
                    value = Math.min(Math.max(value, 0), 23); // el numero esta entre 0 y 23
                  } else {
                    value = 0; // si no es un número válido, asigna 0
                  } setHoraEncendido(value);
                },
              }}
              error={errors.horaEncendido}
              //helperText={errors.horaEncendido ? "Campo requerido" : ""}
            />
            <TextField size="small" value={horaApagado} type="number" required id="hora-apagado" label="Hora Apagado" style={{ width: 223 }}
              inputProps={{
                min: 0,
                max: 24,
                step: 1,
                onChange: (event) => {
                  let value = parseInt(event.target.value);
                  if (!isNaN(value)) {
                    value = Math.min(Math.max(value, 0), 23); // el numero esta entre 0 y 23
                  } else {
                    value = 0; // si no es un número válido, asigna 0
                  } setHoraApagado(value);
                },
              }}
              error={errors.horaApagado}
              //helperText={errors.horaApagado ? "Campo requerido" : ""}
            />
            <TextField
              size="small"
              value={dias}
              required
              id="dias"
              label="Dias"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDias(event.target.value);
              }}
              onBlur={handleBlur}
              error={errors.dias}
              helperText={errors.dias ? "Error. Debe ser [1,2,3,4,5,6,0]" : ""}
            />
            <TextField size="small" value={comentario} id="Comentario" label="Comentario"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setComentario(event.target.value);
              }} />
            <TextField size="small" value={feActualizacion} disabled id="fe-actualizacion" label="Fecha Actualizacion" />
            <TextField size="small" value={feCrecion} disabled id="fe-crecion" label="Fecha Crecion" />
            <Stack justifyContent="right" direction="row" spacing={1} sx={{ border: 0, p: 1 }}>
              {errorMessage && (
                <Typography color="error" variant="body2" sx={{ ml: 2, alignSelf: 'center' }}>
                  {errorMessage}
                </Typography>
              )}
              <Button disableElevation onClick={props.handleCloseMonitor}>
                Cancelar
              </Button>
              <Button variant="contained" disableElevation onClick={handleAccept} >
                Aceptar
              </Button>
            </Stack>
          </Box>
        }
      </Box>
    </Fragment>
  );
}
export default ViewNewEdit;
