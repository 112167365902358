import React, { useMemo, useEffect } from 'react';
import { Box, Stack, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import AppLoading from '../app/AppLoading.tsx';
import fetchData from '../app/FetchData.js';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});
const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";
const Example = (props:any) => {

  const [data, setData] = React.useState([]);
  const [blocking, setBlocking] = React.useState(false);

  useEffect(() => {        
    const catData = async () => {           
      setBlocking(true);
      const resp = await fetchData(url, "rds-aws", {"action":"get"});
      setData(resp.rows);
      setBlocking(false)
    };      
    catData();              
  }, []);

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const countRows = useMemo(
    () => data.reduce((acc, curr) => acc + 1, 0),
    [],
  );

  const columns = useMemo(
    () => [
      { header: 'cliente', accessorKey: 'cliente', },
      { header: 'cuenta', accessorKey: 'cuenta',
        // Footer: () => (
        //   <Stack>
        //     total rows:{countRows}
        //     <Box color="warning.main">{countRows}</Box>
        //   </Stack>
        // ),
      },
      { header: 'rds_id', accessorKey: 'rds_id',},
      { header: 'engine', accessorKey: 'engine',},
      { header: 'store_type', accessorKey: 'store_type',},      
      { header: 'store_throughput', accessorKey: 'store_throughput',},      
      { header: 'iops', accessorKey: 'iops',},
      { header: 'store', accessorKey: 'store',},
      { header: 'max_store', accessorKey: 'max_store',},
      { header: 'rds_class', accessorKey: 'rds_class',},
      { header: 'status', accessorKey: 'status',},
      { header: 'pais', accessorKey: 'pais',},
      { header: 'producto', accessorKey: 'producto',},
      { header: 'tipo_despliegue',accessorKey: 'tipo_despliegue',}   
    ],[countRows]
  );

  return (
    <React.Fragment>
    {
      blocking ?  
        <AppLoading {...props}/>
      :
      <MaterialReactTable
        columns={columns}  data={data}
        enableColumnResizing enableGrouping enableStickyHeader enableStickyFooter
        initialState={{density: 'compact', expanded: true, pagination: { pageIndex: 0, pageSize: 100 },}}
        muiToolbarAlertBannerChipProps={{ color: 'primary' }}
        muiTableContainerProps={{ sx: {  maxHeight: '70vh' } }}      
        renderTopToolbarCustomActions= {({ table }) => (
          <Box sx={{display: 'flex',gap: '16px',padding: '4px',flexWrap: 'wrap',}}>
            <Button onClick={handleExportData} startIcon={<FileDownloadIcon />}>Export All Data</Button>
          </Box>
        )}      
      />
    }
    </React.Fragment>  
  );
};

export default Example;
