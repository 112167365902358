import React, { useMemo } from 'react';
import { Box, Typography, Grid, Avatar, Chip, Stack } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import AppLoading from '../../app/AppLoading.tsx';


const ProductChip = ({ product, count }) => {
  return (
    <Chip
      label={count}
      sx={{ margin: 1, padding: 2 }}
      avatar={
        <Avatar
          sx={{
            width: 'auto', // Ajusta el ancho automáticamente según el contenido
            height: 30, // Altura fija para mantener la consistencia
            padding: '0 50px', // Espaciado horizontal para el texto
            fontSize: '14px', // Tamaño del texto dentro del Avatar
            backgroundColor: '#f0f0f0', // Color de fondo opcional
            color: '#333', // Color del texto opcional
          }}
        >
          {product}
        </Avatar>
      }
    />
  );
};

const ViewClientes = ({ data, loading }) => {
  // Función para calcular las cantidades de productos
  const calculateProductCounts = (data) => {
    const productCounts = {};

    data.forEach(item => {
      const normalizedProduct = item.producto.toUpperCase(); // Normalizar a mayúsculas
      if (productCounts[normalizedProduct]) {
        productCounts[normalizedProduct] += 1;
      } else {
        productCounts[normalizedProduct] = 1;
      }
    });

    return productCounts;
  };

  // Calcular las cantidades de productos
  const productCounts = calculateProductCounts(data);

  // Definir las columnas para la tabla
  const columns = useMemo(() => [
    {
      accessorKey: 'cuenta',
      header: 'Cuenta',
      size: 200,
      filterVariant: 'multi-select',
      filterSelectOptions: Array.from(new Set(data.map(item => item.cuenta))),
    },
    {
      accessorKey: 'name_database',
      header: 'Name Database',
      size: 200,
    },
    {
      accessorKey: 'producto',
      header: 'Producto',
      size: 150,
      filterVariant: 'multi-select',
      filterSelectOptions: data.map(item => item.producto).filter((value, index, self) => self.indexOf(value) === index),
    },
  ], [data]);

  // Crear la instancia de la tabla fuera de las condiciones
  const table = useMaterialReactTable({
    columns,
    data,
    initialState: {
      showColumnFilters: false,
      density: 'compact',
    },
    renderDetailPanel: ({ row }) => (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body2">Compañías:</Typography>
            <Typography variant="caption">{row.original.razon_social}</Typography>
          </Grid>
        </Grid>
      </Box>
    ),
  });

  // Lista de productos a mostrar en las tarjetas
  const productList = [
    '85',
    'ERP.NET',
    'ERP.VISUAL',
    'SBS.NET',
    'SBS.VISUAL',
    'AUTOGESTION',
    'CALLCENTER',
    'NOMINA',
    'POS'
  ];

  return (
    <Box sx={{ marginTop: '20px' }}>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <AppLoading />
        </Box>
      ) : (
        <>
          {/* Renderizado de chips en lugar de tarjetas */}
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', justifyContent: 'center', marginBottom: 2 }}>
            {productList.map(product => {
              const normalizedProduct = product.toUpperCase(); // Normalizar a mayúsculas
              return (
                <ProductChip
                  key={normalizedProduct}
                  product={product}
                  count={productCounts[normalizedProduct] || 0}
                />
              );
            })}
          </Stack>

          <MaterialReactTable table={table} />
        </>
      )}
    </Box>
  );
};

export default ViewClientes;
