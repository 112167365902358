import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, purple, grey } from '@mui/material/colors';
import imgGC from '../login/goyito-cloud.png' 
import './AppLogin.css';
import LoginButton from "../login/login.js";
import { useEffect } from 'react';
import { gapi } from 'gapi-script';
import { useNavigate } from "react-router-dom";

const clientId = "716269390985-nrcgg6tk8tnf5fsuj0pnin1emv2s3t0g.apps.googleusercontent.com";

function Copyright(props) {
  return (
    <Typography variant="body2" 
      color="text.secondary" 
      alignItems="center"
      {...props}>
      {'Copyright © siesa cloud -- '}      
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme({
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
      darker: blue[900],
      darkerMax: blue[1100],
    },
    secondary: {
      main: blue[500],
    },
    otro1: {
      main: grey[300],
    },
  },
});

export default function SignIn() {

  const navigate = useNavigate();

  useEffect(()=>{
    function start(){
      gapi.client.init({
        clientId: clientId,
        scope: "",
        response_type: 'permission'
      })
    };
    gapi.load('client:auth2', start);
  });

  const onFailureLoginGoogle = (res) => {
    console.log("login failed! res: ", res);
  }

  const onSuccessLoginGoogle = async (res) => {
      /** validar el resgistro mail este dentro de una persistencia de siesa */
      console.log("res.........", res);
      localStorage.setItem('accessTokenG', res.tokenObj.access_token);
      localStorage.setItem('id_token', res.tokenObj.id_token);
      const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/login";                      
      const resp = await catchData(url, {action: "login"});
      if(resp.status && resp.status == 200 && resp.statusLogin == 'ok'){
        navigate('App');
      }else{
        // aunque el login es valido para google no tiene accesos a DS Siesa
        // error numero 2
      }
  }

  const catchData = async(url, params) => {      
      let result = {};
      try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'authorizationToken': localStorage.getItem('id_token')
            },
            body: JSON.stringify(params),
        });                   
        result = await response.json();
        result.status = response.status;
      } catch (error) {
        console.error('Error fetching data:', error);
        result.status=-1;
        result.error=-error;
      }
      return result;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box
        component="main" 
        sx={{width:'100%', padding:'0px'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '85vh',
            justifyContent: "center",
            backgroundColor: 'otro1.main'
          }}
        >
          <img src={imgGC}/>
          <Typography component="h1" variant="h5" align= "center">
            Sign in to DS siesa
          </Typography><br/>
          <LoginButton onSLogInG={(res) => onSuccessLoginGoogle(res)}/>             
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '15vh',
            justifyContent: "center", 
            backgroundColor: 'primary.darker',         
          }}
        >
          <Copyright sx={{ alignItems:'center', color:'#FFF'}} />
        </Box>
      </Box>
     </ThemeProvider>
  );
}