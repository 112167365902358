import React, { useMemo , Fragment } from 'react';
import { Box,  Button , IconButton} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MaterialReactTable, type MRT_ColumnDef, } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { styled } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Modal from '@mui/material/Modal';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ViewData from './ViewData.tsx'
import AppLoading from '../../app/AppLoading.tsx';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 54,
  pl:2,
  pr:2,
  pt:0,
  pb:2,
} 

const Example = (props:any) => {
  const [horaIni, setHoraIni] = React.useState<Dayjs | null>(dayjs(new Date()));  
  const [horaFin, setHoraFin] = React.useState<Dayjs | null>(dayjs(new Date()));  
  const data:any = props.data&&props.data.body?props.data.body.rows:[];
  
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };

  const [rowSelect, setRowSelect] = React.useState("node");  
  const [openMonitor, setOpenMonitor] = React.useState(false);  
  const handleOpenMonitor = (row) => {    
    setOpenMonitor(true);
    setRowSelect(row);
  };

  const handleCloseMonitor = () => setOpenMonitor(false);

  const CustomSlider = styled(Slider)({
    width: 150,
    color: 'var(--color)',
    '& .MuiSlider-thumb': {
      [`&:hover, &.Mui-focusVisible`]: {
        boxShadow: '0px 0px 0px 8px var(--box-shadow)',
      },
      [`&.Mui-active`]: {
        boxShadow: '0px 0px 0px 14px var(--box-shadow)',
      },
    },
  });

  const entre36y70 = {
    '--color': '#4caf50',
    '--box-shadow': 'rgb(76, 175, 80, .16)',
  } as React.CSSProperties;
  
  const antes0y35 = {
    '--color': '#1976d2',
    '--box-shadow': 'rgb(25, 118, 210, .16)',    
  } as React.CSSProperties;

  const entre71y100 = {
    '--color': '#ED512F',
    '--box-shadow': 'rgb(76, 175, 80, .16)',
  } as React.CSSProperties;

  const cellRender = ({
    cell
  }) => <Box sx={{ width: 120, paddingLeft: 2 }}>
          <Typography id="non-linear-slider" sx={{
              color: cell.getValue()<35?'info.main':cell.getValue()>70?'error.main':'success.main',
              fontWeight: 'bold',
              fontSize: 12,
            }} gutterBottom >
            {cell.column.columnDef.header}: {cell.getValue()}
          </Typography>
          <CustomSlider
            style={cell.getValue()<35?antes0y35:cell.getValue()>70?entre71y100:entre36y70}
            value={cell.getValue()}                                         
          />
        </Box>;
  // const cellLink = ({
  //   cell
  // }) => <div>
  //       <Button onClick={() => handleOpenMonitorIdInstance(cell.getValue())}>{cell.getValue()}</Button>      
  //     </div>;
  const columns = useMemo(
    () => [
      { header: 'cuenta', accessorKey: 'cuenta', 
        // Footer: () => (
        //   <Stack>
        //     total rows:
        //     <Box color="warning.main">{countRows}</Box>
        //   </Stack>
        // ),
      },            
      { header: 'instance_id', accessorKey: 'instance_id', },
      { header: 'instance_name', accessorKey: 'instance_name', },     
      { header: 'Cpu Avg',  accessorKey: 'cpu_avg',           
          Cell: cellRender,
          filterVariant: 'range-slider',
          filterFn: 'betweenInclusive',
          muiFilterSliderProps: {marks: true, max: 100, min: 0, step: 5,},
      },
      { header: 'Cpu Max',  accessorKey: 'cpu_max', 
          Cell: cellRender,
          filterVariant: 'range-slider',
          filterFn: 'betweenInclusive',
          muiFilterSliderProps: {marks: true, max: 100, min: 0, step: 5,},
      },      
      { header: 'Ram Avg',  accessorKey: 'memoria_avg',
          Cell: cellRender,
          filterVariant: 'range-slider',
          filterFn: 'betweenInclusive',
          muiFilterSliderProps: {marks: true, max: 100, min: 0, step: 5,},
      },
      { header: 'Ram Max',  accessorKey: 'memoria_max', 
          Cell: cellRender,
          filterVariant: 'range-slider',
          filterFn: 'betweenInclusive',
          muiFilterSliderProps: {marks: true, max: 100, min: 0, step: 5,},
      },
      { header: 'Ram',  accessorKey: 'memoria_ram', },
      { header: 'Virtual Cpu',  accessorKey: 'vcpus', },
      { header: 'Cores',  accessorKey: 'cores',},
      
      { header: 'cliente', accessorKey: 'cliente', },
      { header: 'ip_privada', accessorKey: 'ip_privada', },      
      { header: 'ip_publica',  accessorKey: 'ip_publica', },
      { header: 'instance_type',  accessorKey: 'instance_type', },
      
    ],[]
  );
  const customTableContainerProps = {
    sx: { maxHeight: props.windowHeight-262 },
  };
  return (    
    <React.Fragment>      
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['TimePicker', 'TimePicker']}>
          <TimePicker
            label="Hora Inicial"
            value={horaIni}
            onChange={(newValue) => setHoraIni(newValue)}
            views={['hours']}
          />
          <TimePicker
            label="Hora final"
            value={horaFin}
            onChange={(newValue) => setHoraFin(newValue)}
            views={['hours']}
          /> 
          <Button variant="contained" size="large" onClick={() => props.onClickTEAM({horaIni, horaFin})}>
              Get Métricas
          </Button>         
        </DemoContainer>
      </LocalizationProvider>
      {
      props.blocking ?  
        <AppLoading {...props}/>
        :
        <MaterialReactTable
          columns={columns}
          data={data}
          enableColumnResizing
          enableGrouping
          enableStickyHeader
          enableStickyFooter
          initialState={{
            density: 'compact',
            expanded: true, //expand all groups by default
            pagination: { pageIndex: 0, pageSize: 100 },          
          }}
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          muiTableContainerProps={customTableContainerProps}     
          enableRowActions
          displayColumnDefOptions= {{
            'mrt-row-actions': {
              header: 'actions', //change header text
              //size: 100, //make actions column wider
            },
          }} 
          renderRowActions={({ row, table  }) => [          
            <Box>            
              <IconButton onClick={() => handleOpenMonitor(row)}>
                <AnalyticsIcon/>
              </IconButton> 
            </Box>

          ]}

          renderTopToolbarCustomActions= {({ table }) => (
            <Box
              sx={{
                display: 'flex',
                gap: '16px',
                padding: '4px',
                flexWrap: 'wrap',
              }}
            >
              <Button
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}>
                Export All Data
              </Button>
            </Box>
          )}
        />
      }
    <Modal
        open={openMonitor}
        onClose={handleCloseMonitor}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >     
       <Box sx={style}>
         <ViewData 
          row={rowSelect} 
          timeIni={horaIni}
          timeFin={horaFin}
          windowHeight={props.windowHeight}
          title={"Métricas Siesa Cloud"}
          handleCloseMonitor={handleCloseMonitor}
          />
        </Box>
      </Modal> 
    </React.Fragment>
  );
};

export default Example;