import React, { useMemo } from 'react';
import { Box, Stack, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

const Example = (props:any) => {

  const data:any = props.data&&props.data.body?props.data.body.rows:[];

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };
  
  const columns = useMemo(
    () => [
      {
        header: 'id Instance',
        accessorKey: 'idInstance',
      },
      {
        header: 'Instance Base',
        accessorKey: 'instanceTypeBase',
      },
      {
        header: 'Instance Resize',
        accessorKey: 'instanceTypeRightsize',
      },
      {
        header: 'Hora Base',
        accessorKey: 'horaBase',
      },
      {
        header: 'Hora Resize',
        accessorKey: 'horaRightsize',
      },
      {
        header: 'Dias',
        accessorKey: 'dias',
      },
      {
        header: 'Instancia',
        accessorKey: 'nombreInstancia',
      },
      {
        header: 'Comentario',
        accessorKey: 'comentario',
      },   
    ],[]
  );

  const customTableContainerProps = {
    sx: { maxHeight: props.windowHeight-270 },
  };

  return (
    <React.Fragment> 
      <Stack spacing={2} sx={{ width: 300, padding:1 }}>
        <Autocomplete
          disablePortal
          id="cuentas-resize"
          options={cuentasResize.map((option) => option.title)}
          renderInput={(params) => <TextField {...params} label="Cuentas-Resize" />}
          onInputChange={(event, newCuentaValue) => props.onClickTEAM({newCuentaValue})}
        />
      </Stack>

      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnResizing
        enableGrouping
        enableStickyHeader
        enableStickyFooter
        initialState={{
          density: 'compact',
          expanded: true, //expand all groups by default
          pagination: { pageIndex: 0, pageSize: 100 },
        }}
        muiToolbarAlertBannerChipProps={{ color: 'primary' }}
        muiTableContainerProps={customTableContainerProps}
        renderTopToolbarCustomActions= {({ table }) => (
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '4px',
              flexWrap: 'wrap',
            }}
          >
            <Button
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
            >
              Export All Data
            </Button>
          </Box>
        )}
      />
    </React.Fragment> 
  );
};

const cuentasResize = [
  { title: 'pdn'},
  { title: 'pdn-m1'},
  { title: 'pdn-m2'},
  { title: 'pdn-m3'},
  { title: 'pdn-m4'},
  { title: 'pdn-zeus'},
  { title: 'staging'},
  
];

export default Example;
