import React, { useEffect } from "react";
import AppView from './AppView.tsx';
import { useNavigate } from "react-router-dom";
import fetchData from '../app/FetchData.js';

const urlEc2 = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/" 

class App extends React.Component {

    constructor(props: any) {
      super(props);
      this.state = {
        view: {name:"Home!!!"},
        login: false,
        data: 'none',
        resources: [],
        blocking: false,
      };
      this.handleClick = this.handleClick.bind(this);
      this.handleClickTEAM = this.handleClickTEAM.bind(this);
    }

    componentDidMount = async() => {
      let result = await fetchData(urlEc2, "resource",  {action: "get-resurce-by-usuario"});
      // console.log("result.....................", result);
      this.setState({
        resources: result,
      });
    }    
  
    handleClick = async (number: any) => {
      //console.log("number.....", number);
        if(number.loadInit){
          await this.catchDataState(number, {"action": "get"});
        }else{
          this.setState({
            view: number,
            data: [],
          });
        }
    }

    handleClickTEAM = async (dataPage: any) => {
      console.log("dataView......", this.state.view, "dataPage......", dataPage);
      if(this.state.view.name == "ec2-aws-metricas"){
        let dateIni = dataPage.horaIni.$d;
        let dateFin = dataPage.horaFin.$d;
        dateIni.setMinutes(0);
        dateFin.setMinutes(5); 
        console.log(" fechas el milisegundos...", dateIni.getTime(), dateFin.getTime());
        const params = {
            timeIni: dateIni.getTime(),
            timeFin: dateFin.getTime(),
            action: "metricas"
        };
        if(dataPage && dataPage.horaIni && dataPage.horaFin){
            await this.catchDataState(this.state.view, params);
        }
      }else if(this.state.view.name == "concurrencia-db"){
        let dateIni = dataPage.mesIni.$d;        
        let dtIniMonth = setFecha(dateIni, dateIni.getMonth());
        let dtFinMonth = setFecha(dateIni, dateIni.getMonth()+1);
        console.log("dtIniMonth....", dtIniMonth, "...dtFinMonth...", dtFinMonth, "...dtIniMonth.getTime()...", dtIniMonth.getTime(), "...dtFinMonth.getTime()...", dtFinMonth.getTime())
        const params = {
          mesIni: dtIniMonth.getTime(),
          mesFin: dtFinMonth.getTime(),
          action: "get"
        };
        await this.catchDataState(this.state.view, params);
      }else if( this.state.view.name == "ec2-aws-resize" 
        || this.state.view.name == "ec2-aws-resize-legacy"
        || this.state.view.name == "ec2-aws-stop"){
        if(dataPage.newCuentaValue){
          const params = {
            account: dataPage.newCuentaValue,
            action: "get"
          };
          await this.catchDataState(this.state.view, params);  
        }
      }else{
        console.log("error en la configuracion no deberia ser contactar a diego hernandez" );
      }
    }

    catchDataState = (number: any, params:JSON) => {
      this.setState({
        blocking: true,
      });
      //console.log("number.view........", number.view);
      fetch(number.urlGw, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'authorizationToken': localStorage.getItem('id_token')
        },
        body: JSON.stringify(params),
      })
      .then(res => res.json())
      .then(
        (result) => {              
          this.setState({
                view: number,
                data: result,
                blocking: false,
          });
        },
        (error) => {
            console.log("error call ms...", error);
            this.setState({                 
              login: true
            });
        }
      );
    }

    getAlertas(){
        return [
            'alerta de disco'
            , 'alertas cpu'
            , 'alertas memoria'];
    }
  
    render() {
        // console.log("this.state.resources............", this.state);
        const dataTree = this.state.resources;
        const alertas = this.getAlertas();
        return (
            <React.Fragment>
                <AppView 
                    dataView={this.state.view}
                    data={this.state.data}
                    login={this.state.login}
                    onClick={this.handleClick}
                    onClickTEAM={this.handleClickTEAM}
                    title={this.state.view.name}
                    dataTree={dataTree}
                    alertas={alertas}
                    blocking={this.state.blocking}
                />
            </React.Fragment>          
        );
    }
}

export default function AppNav(props: any) {
    const navigate = useNavigate();
    const accessTokenG = localStorage.getItem('id_token');
    useEffect(() => {
        if(accessTokenG == null){
          navigate("/")
        }
      }, []);
    return  <App/>;
    // return  <AppLoading/>;
}


function setFecha(dt:Date, month:Integer) {
  const d = new Date();
  d.setFullYear(dt.getFullYear());
  d.setMonth(month);
  d.setDate(1);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  return d;
}