import { useState, CSSProperties } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import { Box } from "@mui/material";
import Typography from '@mui/material/Typography';

const override: CSSProperties = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
};

function AppLoading(props) {
  let [loading, setLoading] = useState(true);
  return (
    <Box sx={{position:"relative", top: props.windowHeight/3, textAlign:"center"}}>
      <BeatLoader        
        color="rgba(0, 0, 0, 1)"
        loading={loading}
        cssOverride={override}
        size={25}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <Typography variant="subtitle2" gutterBottom>   
        {props.titleCustom?props.titleCustom:"loading "+props.title}
      </Typography>
    </Box>
  );
}

export default AppLoading;