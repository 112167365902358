// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <h3>welcome to goyito-cloud is a website where all dreams in siesa cloud come true!!! let´s go!!!</h3>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals



import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import AppRoute from './componentes/routes/AppRoute.js';
import { BrowserRouter } from "react-router-dom"; // New line

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppRoute/>
    </BrowserRouter>    
  </React.StrictMode>
);