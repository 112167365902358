import React, { useEffect } from "react";

export default class HomePest extends React.Component {

    constructor(props: any) {
      super(props);
      
    }      
  
    render() {
        return (
            <React.Fragment>
                <br></br>
             <h2>Visión</h2>
             <p>
              Ser una plataforma de apalacamiento a los procesos de la nube,
              para que la operación sea mas proativa,
              podamos planear mejor el hacer de nuestros clientes
              y el tiempo de estar bien sea menor.
             </p>
             <h2>Misión</h2>
             <p>
              Desarrollar herramientas para conocer el estado de 
              los artefactos nube del cliente y asi conocerlo mejor
              en busca de un mundo de posibilidades de mejora. </p>
             <h2>DS Siesa</h2>
             <p>
              Directo al Servicio.... </p>             
            </React.Fragment>          
        );
    }
}