import React, { useMemo } from 'react';
import { Box, CircularProgress, Typography, Grid } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

const ViewEC2Resizes = ({ data = [], loading = false }) => {

  const groupDataByInstanceId = (data) => {
    const groupedData = data.reduce((acc, item) => {
      const { instance_id } = item;
      if (!acc[instance_id]) {
        acc[instance_id] = {
          ...item,
          details: [{ dias: item.dias, hora_base: item.hora_base, hora_resize: item.hora_resize }]
        };
      } else {
        acc[instance_id].details.push({ dias: item.dias, hora_base: item.hora_base, hora_resize: item.hora_resize });
      }
      return acc;
    }, {});
    return Object.values(groupedData);
  };

  const groupedData = useMemo(() => groupDataByInstanceId(data), [data]);

  const mapDays = (days) => {
    const dayMap = {
      0: 'Domingo',
      1: 'Lunes',
      2: 'Martes',
      3: 'Miércoles',
      4: 'Jueves',
      5: 'Viernes',
      6: 'Sábado',
    };
    if (typeof days === 'string') {
      days = days.replace(/[{}]/g, '').split(',').map(Number);
    }
    return days.map(day => dayMap[day]).join(', ');
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'cuenta',
      header: 'Cuenta',
      size: 150,
      filterVariant: 'multi-select',
      filterSelectOptions: groupedData.map(item => item.cuenta).filter((value, index, self) => self.indexOf(value) === index),
    },
    {
      accessorKey: 'instance_id',
      header: 'Instance ID',
      size: 200,
    },
    {
      accessorKey: 'instance_name',
      header: 'Instance Name',
      enableGrouping: false,
      size: 200,
    },
    {
      accessorKey: 'producto',
      header: 'Producto',
      size: 200,
      filterVariant: 'multi-select',
      filterSelectOptions: groupedData.map(item => item.producto).filter((value, index, self) => self.indexOf(value) === index),
    },
  ], [groupedData]);

  const table = useMaterialReactTable({
    columns,
    data: groupedData,
    enableGrouping: true,
    initialState: {
      showColumnFilters: true,
      density: 'compact',
    },
    renderDetailPanel: ({ row }) => (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={1}>
          {row.original.details.map((entry, index) => (
            <React.Fragment key={index}>
              <Grid item xs={4}>
                <Typography variant="body2">Días Resize:</Typography>
                <Typography variant="caption">{mapDays(entry.dias)}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">Horario Base:</Typography>
                <Typography variant="caption">{entry.hora_base}</Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography variant="body2">Horario Resize:</Typography>
                <Typography variant="caption">{entry.hora_resize}</Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    ),
  });

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
      {loading ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <MaterialReactTable table={table} />
      )}
    </Box>
  );
};

export default ViewEC2Resizes;
