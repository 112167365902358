import React, { useState, useEffect} from "react";
import ReactApexChart from 'react-apexcharts';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
//get-by-time
const RenderDinamico = (props:any) => {   
  
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    console.log("newValue........", newValue);
    setValue(newValue);
  };
  //console.log("props..........", props);
  return (             
    <React.Fragment> 
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Métricas CPU" {...a11yProps(0)} />
            <Tab label="Métricas Memoria" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <ApexBarAgrupa categories={props.categories} series={JSON.parse(props.series)} />          
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
         <ApexBarAgrupa categories={props.categories} series={JSON.parse(props.seriesRam)} />
        </CustomTabPanel>        
      </Box>
    </React.Fragment> 
  );
}

function a11yProps(index: number) {  
  return {  id: `simple-tab-${index}`,  'aria-controls': `simple-tabpanel-${index}`, }; 
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
         {children}
        </Box>
      )}
    </div>
  );
}

function ApexBarAgrupa(props:any){   
    let options = { 
      yaxis: {
        max:100
      },    
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MM \'yy',
            day: 'dd MM',
            hour: 'HH:mm'
          }
        },           
        categories: JSON.parse(props.categories)
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        id: 'procesosEc2',
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 2,
          borderRadiusApplication: 'end', // 'around', 'end'
          borderRadiusWhenStacked: 'last', // 'all', 'last'
          dataLabels: {
            total: {
              formatter: function (val, opts) {
                return val.toFixed(2);
              },
              enabled: true,
              style: {
                fontSize: '12px',
                fontWeight: 900
              }
            }
          }
        },
      },      
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    };

    return <ReactApexChart options={options} series={props.series} type="bar" height={350} />
}
export default RenderDinamico;