import {GoogleLogout} from 'react-google-login';

const clientId = "716269390985-nrcgg6tk8tnf5fsuj0pnin1emv2s3t0g.apps.googleusercontent.com";

function Logout(props){

    /*const onSuccess = () => {
        console.log("log out successfull!!!!!!! ");
    }*/    

    return (
        <div id='signInButton'>
            <GoogleLogout
                clientId={clientId}
                buttonText="log-Out"
                onLogoutSuccess={props.onFLogOutG}            
            />
        </div>
    );
}

export default Logout;