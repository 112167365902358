import React, { useRef, useEffect } from "react";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { MaterialReactTable } from 'material-react-table';
import ViewCpu from './ViewCpu.tsx';
import ViewRam from './ViewRam.tsx';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
    
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`} {...other} >
      {value === index && (<Box sx={{ p: 3 }}> {children} </Box>)}
    </div>
  );
}

const RenderDinamico = (props: any) => {
  const [value, setValue] = React.useState(0);
  const [subValue, setSubValue] = React.useState([0, 0, 0]);

  console.log('Render Dinamico..', props.dataDiscos);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: number) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  return (
    <React.Fragment>      
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <ToggleButtonGroup value={value} exclusive onChange={handleChange} aria-label="main tabs">
          <ToggleButton value={0} aria-label="CPU Max">CPU Max</ToggleButton>
          <ToggleButton value={1} aria-label="Ram Max">Ram Max</ToggleButton>
          <ToggleButton value={2} aria-label="Detalles Discos">Discos Duros Ec2</ToggleButton>
          <ToggleButton value={3} aria-label="Usuarios Conectados">Usuarios Conectados</ToggleButton>
          <ToggleButton value={4} aria-label="Usuarios DA">Usuarios DA</ToggleButton>
        </ToggleButtonGroup>
      </Box> 
      {(value === 0 || value === 1) && (
      <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
                <ToggleButtonGroup
                    exclusive
                    value={props.alignment}
                    onChange={props.onAlignmentChange}
                    aria-label="sub tabs"
                >
                    <ToggleButton value="diario">Diario</ToggleButton>
                    <ToggleButton value="semanal">Semanal</ToggleButton>
                    <ToggleButton value="mensual">Mensual</ToggleButton>
                </ToggleButtonGroup>
            </Box>
      )}
      <CustomTabPanel value={value} index={0}>
          <ViewCpu {...props}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
          <ViewRam {...props}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      {props.dataDiscos && props.dataDiscos.length > 0 ? (
          <MaterialReactTable columns={props.columnsDiscos} data={props.dataDiscos}
            enableGrouping   enableStickyHeader   enableStickyFooter
            initialState={{ density: 'spacious',  expanded: true, pagination: { pageIndex: 0, pageSize: 10 },
            }}
            muiToolbarAlertBannerChipProps={{ color: 'primary' }}/>
          ) : (
            <Box sx={{ p: 3, color: 'error.main' }}>Por favor validar los discos de la instancia</Box>
          )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      {props.tableData && props.tableData.length > 0 ? (
          <MaterialReactTable columns={props.tableColumns} data={props.tableData}
            enableGrouping   enableStickyHeader   enableStickyFooter
            initialState={{ density: 'compact',  expanded: true, pagination: { pageIndex: 0, pageSize: 10 },
            }}
            muiToolbarAlertBannerChipProps={{ color: 'primary' }}/>
          ) : (
            <Box sx={{ p: 3, color: 'error.main' }}>Validar extracción de usuarios</Box>
          )}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        {props.dataUser && props.dataUser.length > 0 ? (
          <MaterialReactTable columns={props.columns} data={props.dataUser}
            enableGrouping   enableStickyHeader   enableStickyFooter
            initialState={{ density: 'compact',  expanded: true, pagination: { pageIndex: 0, pageSize: 10 },
            }}
            muiToolbarAlertBannerChipProps={{ color: 'primary' }}/>
          ) : (
            <Box sx={{ p: 3, color: 'error.main' }}>Validar unidad organizativa de los usuarios</Box>
        )}
      </CustomTabPanel>
    </React.Fragment>
  );
}

export default RenderDinamico;