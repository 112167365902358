import {GoogleLogin} from 'react-google-login';
import { styled } from '@mui/material/styles';

const clientId = "716269390985-nrcgg6tk8tnf5fsuj0pnin1emv2s3t0g.apps.googleusercontent.com";

function Login(props){
    return (
        <div id='signInButton'>        
        <GoogleLogin
            clientId={clientId}
            buttonText="Login"
            onSuccess={props.onSLogInG}
            onFailure={props.onFLogInG}
            response_type='permission'
            cookiePolicy={"single_host_origin"}
            isSignedIn={true}
            theme={"dark"}                        
        />            
        </div>
    )
}

export default Login;