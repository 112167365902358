import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { blue, purple, grey,} from '@mui/material/colors';

export default function MainItems(props) {
    let listItems = "";
    if(props.dataTree && props.dataTree.length > 0){
        listItems = props.dataTree.map((numbers) =>
            <ListItemButton onClick={() => props.onClick(numbers)}>
                <ListItemIcon 
                sx={{minWidth:"48px"}}
                >
                    {/* <DashboardIcon sx={{ color: `#fff`}}/> */}
                    <DashboardIcon 
                        // sx={{ color: 'primary.light'}}
                    />
                </ListItemIcon>     
                <ListItemText primary={numbers.name} />
            </ListItemButton>
        );
    }
    return ( 
        <React.Fragment>
            {listItems}
        </React.Fragment>
    );
}