export default async function fetchData(url, contexto, params){    
    try {
        const response = await fetch(url+contexto, {
            method: 'POST',
            headers: {'Accept': 'application/json','Content-Type': 'application/json','authorizationToken': localStorage.getItem('id_token') },
            body: JSON.stringify(params),
        });   
        const result = await response.json();
        return result.body;       
    } catch (error) {
        throw error;
    }
}