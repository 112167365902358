import React, { useState, useEffect } from 'react';
import { Box, Typography, Tabs, Tab, TextField, Grid } from '@mui/material';
import fetchData from '../../app/FetchData.js';
import AppLoading from '../../app/AppLoading.tsx';
import GraphReport from './graphreport.tsx';

const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";
const context = 'informes';

const cardDetails = [
  { title: "Enviados", action: "correos-enviados-claro-basic", type: 'line' },
  { title: "Abiertos", action: "correos-abiertos-claro", type: 'line' },
  { title: "Entregados", action: "correos-entregados-claro", type: 'line' },
  { title: "Fallidos", action: "correos-fallidos-claro", type: 'line' },
  { title: "Rebotados", action: "correos-rebotados-claro", type: 'line' },
  { title: "Redireccion Fallida", action: "correos-enviofallido-claro", type: 'line' },
  { title: "Retraso entrega", action: "otra-accion", type: 'line' },
  { title: "Click", action: "otra-accion", type: 'line' },
  { title: "Queja", action: "otra-accion", type: 'line' },
  { title: "Rebote", action: "otra-accion", type: 'line' }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ViewEventosClaro = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [timeFilterTab, setTimeFilterTab] = useState(0); // Estado para las pestañas de tiempo
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTimeFilterChange = (event, newValue) => {
    setTimeFilterTab(newValue);
  };

  const getTimeFilter = () => {
    switch (timeFilterTab) {
      case 0: return 'daily';
      case 1: return 'weekly';
      case 2: return 'monthly';
      case 3: return 'custom';
      default: return 'daily';
    }
  };

  const loadData = async () => {
    const selectedCard = cardDetails[selectedTab];
    const timeFilter = getTimeFilter();

    if (selectedCard) {
      setLoading(true);
      try {
        let currentTime;
        switch (timeFilter) {
          case 'monthly':
            currentTime = Math.floor(new Date().getTime() / 1000) - (30 * 24 * 60 * 60);
            break;
          case 'weekly':
            currentTime = Math.floor(new Date().getTime() / 1000) - (7 * 24 * 60 * 60);
            break;
          case 'daily':
            currentTime = Math.floor(new Date().getTime() / 1000) - (24 * 60 * 60);
            break;
          case 'custom':
            if (startDate && endDate) {
              currentTime = Math.floor(new Date(startDate).getTime() / 1000);
            }
            break;
          default:
            currentTime = Math.floor(new Date().getTime() / 1000) - (5 * 24 * 60 * 60);
        }

        const requestPayload = { action: selectedCard.action, timeFilter: currentTime };
        const response = await fetchData(url, context, requestPayload);

        const respData = response; // Ajusta según la estructura real

        if (respData && respData.rows) {
          const parsedData = respData.rows.map(item => ({
            value: parseInt(item.total, 10),
            name: item.formatted_date,
            event_type: item.event_type,
            event_type_desc: item.event_type_desc
          }));

          setData(parsedData);
        } else {
          console.error('Data format error: response is not an array');
        }
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    loadData();
  }, [selectedTab, timeFilterTab, startDate, endDate]);

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', flexDirection: 'column' }}>
      
      {/* Tabs horizontales para seleccionar el filtro de tiempo */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={timeFilterTab} onChange={handleTimeFilterChange} centered>
          <Tab label="Diario" />
          <Tab label="Semanal" />
          <Tab label="Mensual" />
          <Tab label="Personalizado" />
        </Tabs>
      </Box>

      {/* Selector de pestañas verticales */}
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', width: '10%' }}
        >
          {cardDetails.map((card, index) => (
            <Tab key={index} label={card.title} {...a11yProps(index)} />
          ))}
        </Tabs>

        {/* Contenido de los gráficos */}
        <Box sx={{ width: '80%', padding: '16px' }}>
          {timeFilterTab === 3 && (
            <Box sx={{ marginBottom: '20px' }}>
              <TextField
                label="Fecha de inicio"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                sx={{ marginRight: '10px' }}
              />
              <TextField
                label="Fecha de fin"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Box>
          )}

          <Box sx={{ marginTop: '20px' }}>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <AppLoading />
              </Box>
            ) : (
              <GraphReport data={data} type="line" />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewEventosClaro;
