import { Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect, Fragment } from "react";
import RenderDinamico from './RenderDinamico.tsx';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CustomizedDialogs from '../../app/CustomizedDialogs.tsx';
import AppLoading from '../../app/AppLoading.tsx';


const ViewData = (props:any) => {
    const [data, setData] = useState("");    
    const [isLoading, setIsLoading] = useState(true);    
    const [isError, setIsError] = useState(false);
    const [msgError, setMsgError] = useState({});
    const [categories, setCategories] = useState([]);
    const [series, setSeries] = useState([]);
    const [seriesRam, setSeriesRam] = useState([]);
    console.log("props......", props);
    useEffect(() => {
      const fetchData = async (url, params) => {
        try {
          const response = await fetch(url, {
              method: 'POST',
              headers: {'Accept': 'application/json','Content-Type': 'application/json','authorizationToken': localStorage.getItem('id_token') },
              body: JSON.stringify(params),
          });
          const result = await response.json();
          console.log("result.........", result);
          if(result.body){            
            let data =result.body;   
            const htProcesos = new Map();
            const htProcesosRam = new Map();
            let arrTime = [];            
                        
            //console.log("data.length.........", data.length);            
            console.log("data.........", data);
            let i = 0;
            for(let objAgenteProcess of data){
              let arrCM = objAgenteProcess.payload["servicios-cpu"];                    
              for(let objCM of arrCM){               
                if(htProcesos.get(objCM.nombre)){
                  let arrDataPro = htProcesos.get(objCM.nombre);
                  arrDataPro[i] = arrDataPro[i]+parseFloat(objCM.cpu.replace(",", "."));                  
                }else{
                  let arrDataPro=[];
                  for(let j=0; j<data.length;  j++){
                    arrDataPro.push(0.0);
                  }
                  arrDataPro[i] = parseFloat(objCM.cpu.replace(",", "."));
                  htProcesos.set(objCM.nombre, arrDataPro);
                }                
              }


              let prcRamMoment = parseFloat(objAgenteProcess.payload["ram-total"]);              
              let arrMen = objAgenteProcess.payload["servicios-ram"];  
              let sumRam = 0;
              for(let objMen of arrMen){
                if(objMen.nombre == "PercentUsedRAM"){
                  continue;  
                }
                sumRam += objMen.ram;
              }
              let undPrc = prcRamMoment/sumRam;
              for(let objMen of arrMen){                
                if(objMen.nombre == "PercentUsedRAM"){
                  continue;  
                }
                if(htProcesosRam.get(objMen.nombre)){
                  let vlr = objMen.ram*undPrc;
                  let arrDataPro = htProcesosRam.get(objMen.nombre);
                  arrDataPro[i] = arrDataPro[i]+vlr.toFixed(2);;
                }else{
                  let arrDataPro =[];
                  for(let j=0; j<data.length;  j++){
                    arrDataPro.push(0.0);
                  }
                  let vlr = objMen.ram*undPrc;
                  arrDataPro[i] = vlr.toFixed(2);
                  htProcesosRam.set(objMen.nombre, arrDataPro);
                }
              }
  
              arrTime.push(objAgenteProcess.time);
              i++;
            }

                                  
            let dataSeriesCPU=[]; 
            for (const [key, value] of htProcesos) {
              dataSeriesCPU.push({
                    name: key,
                    data: value
                  });
            }

            let dataSeriesRAM=[]; 
            for (const [key, value] of htProcesosRam) {
              dataSeriesRAM.push({
                    name: key,
                    data: value
                  });
            }
            setSeries(JSON.stringify(dataSeriesCPU));
            setSeriesRam(JSON.stringify(dataSeriesRAM));
            setCategories(JSON.stringify(arrTime));
            setData(JSON.stringify({result}));
          }else if(result.messagePS){              
            setMsgError({title: "Error Code "+result.messagePS.code, body:result.messagePS.message});
            setIsError(true);
          }      
        } catch (error) {
          console.error('Error fetching data:', error);          
          setIsLoading(false);
          
          setSeries(JSON.stringify([]));
          setSeriesRam(JSON.stringify([]));
          setCategories(JSON.stringify([]));

          setMsgError({title: "Error Code exceptions", body:error});
          setIsError(true);

        }finally{
          setIsLoading(false);
        }
      };
      /** alistamiento al llamado del fethc otro metodo pero esta muy interesante y abre la mente */
      const params = {
          action: "aws-agente",
          instanceId: props.row.original.instance_id,
          timeIni: props.timeIni.$d.getTime(),
          timeFin: props.timeFin.$d.getTime()
      };       
      const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/ec2-get-agente";
      console.log("props...............", props);       
      fetchData(url, params);
    }, []);

    return (     
        <Fragment>
          {isLoading ? (
            <Box><AppLoading {...props}/></Box>
          ) : (
            <Box>
              <Stack direction="row" spacing={1}>
                <Box sx={{ p:1, width:'50%', alignItems:"center"}}>
                  <Typography id="modal-modal-title" variant="h6" component="h2" sx={{  mt: 1, fontWeight: 'bold' }}>
                    Métricas Siesa Cloud
                  </Typography>
                </Box>
                <Box sx={{width:'50%', textAlign:"right", pt:2}}>
                {isError
                  ? <CustomizedDialogs msgError={msgError}/>
                  : ''
                }
                  <Button disableElevation onClick={props.handleCloseMonitor} >
                      Close
                  </Button>
                </Box>
              </Stack>
              { <Box id="modal-modal-description" sx={{ overflow: 'auto' }}>  
                  {/* <textarea>hola como estas !!!!</textarea>           */}
                  <textarea>{data}</textarea>          
                  <RenderDinamico categories={categories} series={series} seriesRam={seriesRam}/>
              </Box>}
            </Box>
          )}      
        </Fragment>            
    );
}

export default ViewData;