import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import GraphReport from './graphreport.tsx';
import fetchData from '../../app/FetchData.js';
import ViewClientes from './viewclientes.tsx';
import ViewConcurrencia from './viewconcurrencia.tsx';
import ViewEC2Resizes from './viewec2resizes.tsx';
import ViewEC2Apagados from './viewec2apagados.tsx';
import ViewDiscos from './viewdiscos.tsx';
import ViewEventosClaro  from './vieweventosclaro.tsx';
import AppLoading from '../../app/AppLoading.tsx';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EventIcon from '@mui/icons-material/Event';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import PeopleIcon from '@mui/icons-material/People';
import StorageIcon from '@mui/icons-material/Storage';

const url = "https://5djo5qwa3l.execute-api.us-east-1.amazonaws.com/dimo-goyito/ec2/";
const context = 'informes';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
};

const modalStyleConcurrencia = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
};

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  zIndex: 1000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

const initialCardData = [
  {
    title: "Eventos Claro",
    subtitle: "Informe de eventos en el registro de envios claro",
    action: "correos-enviados-claro-basic",
    data: [],
    type: 'line',
    icon: <AnnouncementIcon />
  },
  {
    title: "Notificaciones Claro",
    subtitle: "Fabian Mina",
    data: [],
    type: 'line',
    icon: <NotificationsIcon />
  },
  {
    title: "Instancias Resizes",
    subtitle: "EC2 cambio familia Automatico",
    action: "total-resizes",
    data: [],
    type: 'table',
    icon: <AutoModeIcon />
  },
  {
    title: "Instancias Apagados",
    subtitle: "Ec2 Apagado Automatico",
    action: "total-apagados",
    data: [],
    type: 'table',
    icon: <PowerOffIcon />
  },
  {
    title: "Clientes siesa",
    subtitle: "Informe clientes por producto",
    action: "total-clientes",
    data: [],
    type: 'table',
    icon: <PeopleIcon />
  },
  {
    title: "Concurrencia DB",
    subtitle: "Informe concurrencia",
    action: "concurrencia",
    type: 'table',
    icon: <EventIcon />
  },
  {
    title: "Informe de discos",
    subtitle: "Informe de EBS",
    action: "discos",
    data: [],
    type: 'table',
    icon: <StorageIcon />
  }
];

const MediaControlCard = () => {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', description: '', data: [], type: 'line' });
  const [loading, setLoading] = useState(false);
  const [loadingCard, setLoadingCard] = useState(null);

  const handleOpen = async (card) => {
    setLoading(true);
    setLoadingCard(card.title);

    if (card.title === "Concurrencia DB") {
      setModalContent({ title: card.title, description: '', data: [], type: 'line' });
    } else if (card.title === "Eventos Claro") {
      setModalContent({ ...card, data: [] });
    } else {
      let dtHoy0Horas = new Date();
      dtHoy0Horas.setHours(0);
      dtHoy0Horas.setMinutes(0);
      dtHoy0Horas.setSeconds(0, 0);
      let currentTime = (dtHoy0Horas.getTime() / 1000) - (5 * 24 * 60 * 60);

      const fetchCardData = async () => {
        try {
          let requestPayload;
          if (card.title === "Clientes siesa" || card.title === "Instancias Resizes" || card.title === "Instancias Apagados" || card.title === "Informe de discos") {
            requestPayload = { "action": card.action };
          } else {
            requestPayload = { "action": card.action, "timeFilter": currentTime };
          }

          const respData = await fetchData(url, context, requestPayload);

          if (respData && respData.rows) {
            const parsedData = respData.rows.map(item => {
              if (card.title === "Clientes siesa" || card.title === "Instancias Resizes" || card.title === "Instancias Apagados" || card.title === "Informe de discos") {
                return {
                  cuenta: item.cuenta,
                  instance_id: item.instance_id,
                  instance_name: item.instance_name,
                  producto: item.producto,
                  productos: item.productos,
                  razon_social: item.razon_social,
                  estado:item.estado,
                  dias: item.dias,
                  hora_base: item.hora_base,
                  hora_resize: item.hora_resize,
                  hora_encendido: item.hora_encendido,
                  hora_apagado: item.hora_apagado,
                  name_database: item.name_database,
                  disk: item.disk,
                  total_size: item.total_size,
                  used_space: item.used_space,
                  free_space: item.free_space,
                  free_percent: item.free_percent
                };
              } else {
                return {
                  value: parseInt(item.total, 10),
                  name: item.formatted_date,
                  event_type: item.event_type,
                  event_type_desc: item.event_type_desc
                };
              }
            });
            return parsedData;
          } else {
            console.error(`Datos inválidos recibidos de la lambda para ${card.title}`);
            return [];
          }
        } catch (error) {
          console.error(`Error al obtener datos para ${card.title}:`, error);
          return [];
        }
      };

      const data = await fetchCardData(card);
      setModalContent({ ...card, data: Array.isArray(data) ? data : [] });
    }

    setLoading(false);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <div>
      {loading && (
        <div style={overlayStyle}>
          <AppLoading />
        </div>
      )}
      <Grid container spacing={2}>
        {initialCardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
          <Card sx={{ display: 'flex', height: '200px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', justifyContent: 'space-between', p: 2 }}>
              <CardContent>
                <Typography component="div" variant="h5">
                  {card.title}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                  {card.subtitle}
                </Typography>
              </CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 2 }}>
                <Button variant="contained" onClick={() => handleOpen(card)}>
                  Ver más
                </Button>
                <IconButton>
                <Box sx={{ fontSize: '3rem' }}>
                  {card.icon}
                </Box>
              </IconButton>
              </Box>
            </Box>
          </Card>
        </Grid>
        ))}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={["Concurrencia DB", "Informe de discos", "Instancias Resizes", "Instancias Apagados","Clientes siesa"].includes(modalContent.title) ? modalStyleConcurrencia : modalStyle}>
        <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-title" variant="h6" component="h2">
            {modalContent.title}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {modalContent.description}
          </Typography>
          <Box
            sx={{
              flex: 1,
              overflowY: 'auto',
              mt: 2,
            }}
          >
            {modalContent.title === "Clientes siesa" && <ViewClientes data={modalContent.data} />}
            {modalContent.title === "Concurrencia DB" && <ViewConcurrencia data={modalContent.data} />}
            {modalContent.title === "Instancias Resizes" && <ViewEC2Resizes data={modalContent.data} />}
            {modalContent.title === "Instancias Apagados" && <ViewEC2Apagados data={modalContent.data} />}
            {modalContent.title === "Informe de discos" && <ViewDiscos data={modalContent.data} />}
            {modalContent.title === "Eventos Claro" && <ViewEventosClaro />}
            {!["Clientes siesa", "Concurrencia DB", "Instancias Resizes", "Instancias Apagados", "Informe de discos","Eventos Claro"].includes(modalContent.title) && <GraphReport data={modalContent.data} type={modalContent.type} />}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default MediaControlCard;
