import * as React from 'react';
import { useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutButton from '../login/logout';
import MainItems from './MainItems.js';
import View from './View.tsx';
import { useNavigate } from "react-router-dom";
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { blue, purple, grey,} from '@mui/material/colors';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.siesa.com/">
        siesa
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

const mdTheme = createTheme({
  palette: {
    primary: {
      // light: '#bbdefb',
      //light: '#ffffff',
      // main: '#3f50b5',
      main: '#000',
      //main: '#e3f2fd',      
      dark: '#b0aeae  ',
      contrastText: '#fff',
    },
    secondary: {
      // light: '#ff7961',
      // main: '#f44336',
      main: '#bbdefb',
      dark: '#ba000d',
      contrastText: '#000',
    },    
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: grey[100],
      // color: '#fff',
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(8),
        },
      }),
    },
  }),
);

function AppContent(props: any) {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {    
    setAnchorElUser(event.currentTarget);
    event.stopPropagation();
  };  
  const handleCloseUserMenu = (test) => {
    setAnchorElUser(null);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if(props.login){
      onSuccessLogOutGoogle(null);
    }
  });
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSuccessLogOutGoogle = (res) => {
    console.log(localStorage.getItem('accessTokenG'));
    console.log(localStorage.removeItem('accessTokenG'));
    navigate("/")
  }   
  
  return (  
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar 
          // sx={{ minHeight:'7vh', maxHeight:'7vh'}} 
          position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '12px', // keep right padding when drawer closed             
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
            {/* <MenuIcon /> */}
            <img src={'./favicon-1.ico'} height={25} width={25}/>
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {props.dataView.name}
            </Typography>
            
            <IconButton color="inherit" onClick={handleOpenUserMenu}>
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Menu
              sx={{ mt: '45px', }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {props.alertas.map((setting: any) => (
                <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <IconButton color="inherit" onClick={handleMenu}>              
                <AccountCircle />
            </IconButton>             
          <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar2"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
               <MenuItem onClick={handleClose}>
                <LogoutButton 
                    onFLogOutG = {(res: any) => onSuccessLogOutGoogle(res)} />
                </MenuItem>              
            </Menu>
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [0],
              backgroundColor:"#ffffff"
            }}
          >
            <Box sx={{ 
                alignItems:'center'
                , display: 'flex'
                , width:'100%'
                ,px: [1]
                }}>                  
                  {/* <Button color="inherit"  sx={{width:'100%', fontSize:24, fontWeight: 'bold', textAlign: "left"}}>DS siesa</Button> */}
                  <Box sx={{width:'100%', padding:1, fontSize:22, fontWeight: 'bold', textAlign: "right"}}>
                  DS siesa Cloud
                  </Box>
                  
            <IconButton color="inherit" onClick={toggleDrawer}>
              {/* <ChevronLeftIcon /> */}              
              <img src={'./favicon.ico'} height={25} width={25}/>
            </IconButton>
            </Box>
          </Toolbar>
          <Divider/>
          <List style={{ maxHeight: 600, overflow: 'auto'}} component="nav">
            <MainItems onClick={props.onClick} dataTree={props.dataTree} />
          </List>        
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                //? theme.palette.grey[300]
                ? theme.palette.grey[100]
                : theme.palette.grey[100],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Box sx={{ p:0 }}>
            <Grid container spacing={0} >                        
                <Grid item xs={12}>
                  <Paper elevation={3} sx={{ p: 1, display: 'flex', flexDirection: 'column' }}>
                    <View 
                        dataView={props.dataView} 
                        title={props.title} 
                        data={props.data}                      
                        blocking={props.blocking}
                        onClickTEAM={props.onClickTEAM}
                        />
                  </Paper>                  
                </Grid>
            </Grid>
            {/* <Copyright sx={{ pt: 4 }} /> */}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function AppView(props: any) {
  return  <AppContent 
            {...props}
          />;
}